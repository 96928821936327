export const defaultServerUrl = () => {
  return process.browser
    ? location.origin
    : process.env.SERVER_URLS.split(',')[0]
}

export const getServerUrl = (req) => {
  const proto = req.headers['x-forwarded-proto'] || 'http'
  const serverName = req.headers['x-server-name']
  return serverName ? `${proto}://${serverName}` : defaultServerUrl()
}

export const isTrue = (value) =>
  value === true ||
  value === 'true' ||
  value === 'TRUE' ||
  value === 1 ||
  value === '1'
