import {useState, useEffect} from 'react'
import {breakpointConstants} from '../../bundle/client/style'

/**
 *
 * @param {number} width
 * @returns 'xs' | 'sm' | 'md' | 'lg' | 'xl'
 */
const getScreenSize = (width) => {
  if (width <= breakpointConstants.xs.max) {
    return 'xs'
  }

  if (width <= breakpointConstants.sm.max) {
    return 'sm'
  }

  if (width <= breakpointConstants.md.max) {
    return 'md'
  }

  if (width <= breakpointConstants.lg.max) {
    return 'lg'
  }

  return 'xl'
}

/**
 *
 * @returns {width?: number, height?: undefined, screenSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'}
 */
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    screenSize: undefined
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        screenSize: getScreenSize(window.innerWidth)
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}
