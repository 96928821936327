//@ts-check

import {datadogRum} from '@datadog/browser-rum'
import {css} from 'glamor'
import PropTypes from 'prop-types'
import React, {Fragment, useEffect} from 'react'
import Image from 'react-bootstrap/Image'
import {connect} from 'react-redux'
import Link from 'react-router/lib/Link'
import logo from '../images/vintage_brand.svg'
import {resetError} from '../redux/actions/error'
import {resetNotice} from '../redux/actions/notice'
import ErrorBoundary from './error_boundary'
import Footer from './footer'
import HeaderNav from './headernav'
import Helmet from './helmet'
import {isTrue} from '@/shared/utils'
import {checkIsProdWithoutFake} from '@/shared/helpers/env'
import {pushGTMData} from '@/bundle/client/analytics/pushGTMData'

const style = {
  title: css({
    '@media (max-width: 767px)': {
      padding: '16px 7px'
    }
  })
}

const Main = (props) => {
  useEffect(() => {
    const gtmData = {
      using_stripe_checkout: isTrue(props?.env?.USE_STRIPE_CHECKOUT)
    }
    if (!checkIsProdWithoutFake(props?.env)) {
      gtmData.debug_mode = true
    }
    pushGTMData(gtmData).then((data) => data)
  }, [])

  useEffect(() => {
    // Initialize DataDog Real User Monitoring (RUM)
    datadogRum.init({
      applicationId: props.dataDog.applicationId,
      clientToken: props.dataDog.clientToken,
      site: 'datadoghq.com',
      service: props.dataDog.service,
      env: props.dataDog.environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.0.0',
      // This defaults to 100% if omitted
      sessionReplaySampleRate: 0,
      sessionSampleRate: props.dataDog.rate,
      trackUserInteractions: props.dataDog.trackUserInteractions,
      allowedTracingUrls: [
        {
          match: /https:\/\/.*\.vintagebrand\.com/,
          propagatorTypes: ['tracecontext']
        }
      ]
    })
  }, [props.dataDog])

  return (
    <Fragment>
      <Helmet />
      <div id="wrap">
        <HeaderNav
          location={props.location}
          resetError={resetError}
          resetNotice={resetNotice}
          title={
            <Link to="/" {...style.title}>
              <Image src={logo} />
            </Link>
          }
        />
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
      <Footer params={props.params} location={props.location} />
    </Fragment>
  )
}

Main.initialize = ({dispatch}) =>
  Promise.all([HeaderNav.initialize({dispatch}), Footer.initialize({dispatch})])

const stateToProps = (state) => {
  return {dataDog: state.dataDog, env: state.env}
}

Main.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object.isRequired
}

export default connect(stateToProps)(Main)
