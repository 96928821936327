import compact from 'lodash/compact'
import first from 'lodash/first'

/**
 * Get League abbreviation or label
 * @param {App.Vintage.Base.League} leagueIn
 * @returns {string}
 */
export const league = (leagueIn) => leagueIn.abbreviation || leagueIn.label

/**
 * Get Team display or label
 * @param {App.Vintage.Base.Team} teamIn
 * @returns {string}
 */
export const team = (teamIn) => teamIn.display || teamIn.label

/**
 * Get Event Group label
 * @param {App.Vintage.Base.EventGroup} eventGroupIn
 */
export const eventGroup = (eventGroupIn) => eventGroupIn.label

/**
 * Get Athlete full name
 * @param { App.Vintage.Athlete | App.Vintage.HistoricalAthlete } athleteIn
 * @returns {string}
 */
export const athlete = (athleteIn) =>
  `${athleteIn.first_name} ${athleteIn.last_name}`.replace(/\s+/g, ' ')

/**
 *
 * @param { App.Vintage.Base.Asset &
 *  { labelEvent?: App.Vintage.Event & { eventGroup?: App.Vintage.EventGroup },
 *    labelTeamRevision?: App.Vintage.TeamRevision & { team: App.Vintage.Team },
 *    labelAthleteRevision?: App.Vintage.AthleteRevision & { athlete: App.Vintage.Athlete },
 *    labelLeague?: App.Vintage.League,
 *    labelHistoricalAthlete?: App.Vintage.HistoricalAthlete
 *  }
 * } assetIn
 * @returns {string}
 */
export const asset = (assetIn) =>
  compact([assetYear(assetIn), assetNoYear(assetIn)]).join(' ')

/**
 *
 * @param {App.Vintage.Base.Asset & {labelEvent?: App.Vintage.Base.Event}} assetIn
 * @returns {string}
 */
export const assetYear = (assetIn) =>
  compact([
    (assetIn.labelEvent && assetIn.labelEvent.year) || assetIn.year
  ]).join(' ')

/**
 *
 * @param { App.Vintage.Base.Asset &
 *  { labelEvent?: App.Vintage.Event & { eventGroup?: App.Vintage.EventGroup },
 *    labelTeamRevision?: App.Vintage.TeamRevision & { team: App.Vintage.Team },
 *    labelAthleteRevision?: App.Vintage.AthleteRevision & { athlete: App.Vintage.Athlete },
 *    labelLeague?: App.Vintage.League,
 *    labelHistoricalAthlete?: App.Vintage.HistoricalAthlete
 *  }
 * } assetIn
 * @returns {string}
 */
export const assetNoYear = (assetIn) =>
  compact([
    first(
      compact([
        assetIn.labelEvent &&
          assetIn.labelEvent.eventGroup &&
          eventGroup(assetIn.labelEvent.eventGroup),
        assetIn.labelTeamRevision && team(assetIn.labelTeamRevision.team),
        assetIn.labelAthleteRevision &&
          athlete(assetIn.labelAthleteRevision.athlete),
        assetIn.labelLeague && league(assetIn.labelLeague),
        assetIn.labelHistoricalAthlete &&
          athlete(assetIn.labelHistoricalAthlete)
      ])
    )
  ]).join(' ')

/**
 *
 * @param { App.Vintage.Base.Asset &
 *  { labelEvent?: App.Vintage.Event & { eventGroup?: App.Vintage.EventGroup },
 *    labelTeamRevision?: App.Vintage.TeamRevision & { team: App.Vintage.Team },
 *    labelAthleteRevision?: App.Vintage.AthleteRevision & { athlete: App.Vintage.Athlete },
 *    labelLeague?: App.Vintage.League,
 *    labelHistoricalAthlete?: App.Vintage.HistoricalAthlete
 *  }
 * } assetIn
 * @param { App.Vintage.Base.ProductGroup } productGroup
 * @returns {string}
 */
export const product = (assetIn, productGroup) =>
  `${assetIn ? assetYear(assetIn) + ' Vintage ' + assetNoYear(assetIn) : ''} ${productGroup?.marketing_label || productGroup?.label || ''}`.replace(
    /\s+/g,
    ' '
  )
