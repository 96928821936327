import {combineReducers} from 'redux'

const reducer =
  (type, key, initialState = false) =>
  (state = initialState, action) =>
    action.type === type ? action[key] : state

export default combineReducers({
  mounted: reducer('FILTER_MOUNTED', 'mounted'),
  menu: reducer('FILTER_MENU_SHOW', 'show')
})
