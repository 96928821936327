import {helpers} from '@teamrazr/redux'

const provide = (action) => (dispatch, getState) => {
  const {env} = getState()

  const actions = helpers.createApiActions(
    `${env.CONTENTFUL_HOST}/spaces/${
      env.CONTENTFUL_SPACE_ID
    }/environments/${env.CONTENTFUL_ENVIRONMENT || 'master'}`,
    'entries',
    ['contentful'],
    'contentful'
  )

  return dispatch(action(actions, {access_token: env.CONTENTFUL_ACCESS_TOKEN}))
}

export const getEntryById = (id, include = 3) =>
  provide((actions, query) =>
    actions.get({query: {...query, 'sys.id': id, include}})
  )

export const getLeagueSeoText = (leagueId) =>
  provide((actions, query) =>
    actions.get({
      query: {
        ...query,
        content_type: 'leagueSeoText',
        'fields.leagueId': leagueId
      }
    })
  )

/**
 * @param {string} leagueRef
 */
export const getLeaguePage = (leagueRef) =>
  provide((actions, query) =>
    actions.get({
      query: {
        ...query,
        content_type: 'leaguePage',
        'fields.leagueRef': leagueRef
      }
    })
  )

export const getStructuredLandingPageByUrl = (route) =>
  provide((actions, query) =>
    actions.get({
      query: {
        ...query,
        content_type: 'landingPage',
        'fields.url': route,
        include: 3
      }
    })
  )

export const getYouPickLandingPageByUrl = (route) =>
  provide((actions, query) =>
    actions.get({
      query: {
        ...query,
        content_type: 'youPickLandingPage',
        'fields.url': route,
        include: 3
      }
    })
  )

export const getPageSeoAndFeaturedProductsByUrl = (route) =>
  provide((actions, query) =>
    actions.get({
      query: {
        ...query,
        content_type: 'pageSeoAndFeaturedProducts',
        'fields.url': route,
        include: 3
      }
    })
  )

export const getAthletePageByRef = (ref) =>
  provide((actions, query) =>
    actions.get({
      query: {
        ...query,
        content_type: 'athletePage',
        'fields.athleteRef': ref,
        include: 3
      }
    })
  )

export const getBanner = () =>
  provide((actions, query) =>
    actions.get({
      query: {
        ...query,
        content_type: 'banner'
      }
    })
  )
