import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import React from 'react'
import browserHistory from 'react-router/lib/browserHistory'
import {css} from 'glamor'

const linkStyle = ({buttonColor}) =>
  css({
    backgroundColor: buttonColor,
    color: buttonColor?.toLowerCase() === '#ffffff' ? '#333333' : undefined
  })

const LinkButton = ({as: Button, onClick, to, buttonColor, ...rest}) => {
  return (
    <Button
      {...linkStyle({buttonColor})}
      {...rest}
      href={to}
      onClick={(e) => {
        onClick && onClick()
        e.preventDefault()
        browserHistory.push(e.currentTarget.attributes.href.value)
      }}
    />
  )
}

LinkButton.propTypes = {
  as: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string
}

LinkButton.defaultProps = {
  as: Button
}

export default LinkButton
