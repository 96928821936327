import {helpers} from '@teamrazr/redux'

const shipping = helpers.createApiReducer('shipping')

// Note: we always want to reset items on an update. Shiping items should
// never be merged together.
export default function (state, action) {
  switch (action.type) {
    case 'UPDATE_SHIPPING_SUCCESS': {
      const result = shipping(state, action)
      result.items = action.body
      return result
    }

    default:
      return shipping(state, action)
  }
}
