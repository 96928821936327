import PropTypes from 'prop-types'
import React from 'react'
import {library, icon as faIcon} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon as FontAwesome} from '@fortawesome/react-fontawesome'

// Define all icons we plan to use here
import {
  faArrowRight,
  faArrowLeft,
  faBars,
  faCamera,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCog,
  faCompress,
  faCreditCard,
  faEdit,
  faEllipsisV,
  faExpandArrowsAlt,
  faMinus,
  faPlay,
  faPlus,
  faRedoAlt,
  faSearch,
  faShoppingCart,
  faSlidersH,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons'

import {
  faTwitter,
  faYoutube,
  faPinterest,
  faInstagram,
  faFacebook,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'

export const icons = {
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  bars: faBars,
  camera: faCamera,
  check: faCheck,
  checkCircle: faCheckCircle,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  circle: faCircle,
  circleNotch: faCircleNotch,
  cog: faCog,
  compress: faCompress,
  creditCard: faCreditCard,
  edit: faEdit,
  ellipsisV: faEllipsisV,
  expandArrowsAlt: faExpandArrowsAlt,
  facebook: faFacebook,
  instagram: faInstagram,
  linkedIn: faLinkedin,
  minus: faMinus,
  pinterest: faPinterest,
  play: faPlay,
  plus: faPlus,
  redoAlt: faRedoAlt,
  search: faSearch,
  shoppingCart: faShoppingCart,
  slidersH: faSlidersH,
  times: faTimes,
  trash: faTrash,
  twitter: faTwitter,
  youtube: faYoutube
}

for (const key in icons) {
  library.add(icons[key])
}

/**
 * Helper pure component that makes it easy to render FontAwesome icons.
 * @author wes@teamrazr.com (Wes Carr)
 */
const Icon = (props) => {
  const {icon, size, className, ...rest} = props
  if (process.browser) {
    return (
      <i {...rest}>
        <FontAwesome icon={icon} className={`fa-${size} ${className || ''}`} />
      </i>
    )
  } else {
    return (
      <i
        {...rest}
        dangerouslySetInnerHTML={{
          __html: faIcon(icon, {classes: [`fa-${size}`, className]}).html
        }}
      />
    )
  }
}

Icon.propTypes = {
  icon: PropTypes.object,
  size: PropTypes.oneOf([
    'xs',
    'sm',
    'lg',
    '2x',
    '3x',
    '4x',
    '5x',
    '7x',
    '10x'
  ]),
  className: PropTypes.string
}

Icon.defaultProps = {
  size: 'lg'
}

export default Icon
