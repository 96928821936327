import find from 'lodash/find'
import flattenDeep from 'lodash/flattenDeep'
import sortBy from 'lodash/sortBy'
import {stringify} from './query'

export const assetLeague = (asset) => {
  const {labelLeague, labelEvent, labelTeamRevision, labelAthleteRevision} =
    asset

  if (labelLeague) {
    return labelLeague
  }

  if (labelEvent && labelEvent.eventGroup && labelEvent.eventGroup.eventType) {
    return labelEvent.eventGroup.eventType.league
  }

  if (labelTeamRevision) {
    return labelTeamRevision.league
  }

  if (labelAthleteRevision) {
    return labelAthleteRevision.league
  }

  return null
}

export const assetOwner = (asset) => {
  const {events = [], team_revisions = []} = asset

  // If there is an event defined AND it has an event group with a distributor
  // attached, we treat that as the asset owner. If the event has a home team
  // (primary team) marked, we treat that as the asset owner.
  const [{event} = {}] = events
  if (event) {
    const {event_group, primary_team_revision} = event
    if (event_group) {
      const {distributor} = event_group
      if (distributor) {
        return distributor
      }

      if (primary_team_revision) {
        return primary_team_revision.name
      }
    }
  }

  // Finally, just look for a team associated with this thing and treat that as
  // the owner.
  const [{team} = {}] = team_revisions
  if (team) {
    return team.name
  }
}

export const assetTransform = (asset, assetVariation, variation) => {
  const {metadata} = assetVariation
  const {ratio} = variation.metadata

  let {x = 0, y = 0, scale = 1, rotate = 0} = metadata

  x = x / asset.width
  y = y / asset.height
  const height = scale
  const width = (asset.height * ratio * scale) / asset.width

  return `${width},${height},${-x},${-y},${rotate}`
}

export const assetUrl = (host, asset, assetVariation, variation, height) => {
  const transform = assetTransform(asset, assetVariation, variation)
  let query = {a: `${asset.location}$${transform}`}
  if (height) {
    query.h = height
  }
  return `${host}/asset?${stringify(query)}`
}

export const productImages = (product, variation, sku) =>
  sortBy(
    product.images.filter(
      ({asset_variation_id: avId, product_sku_id: skuId}) =>
        (!avId || avId === variation.id) && (!skuId || skuId === sku.id)
    ),
    'sort'
  )

export const productImageUrl = (
  host,
  product,
  asset,
  variation,
  productImg,
  height = 300
) => {
  const query = {
    ...productImg?.metadata,
    h: height
  }

  if (asset && variation) {
    const assetVar = find(asset.variations, {asset_variation_id: variation.id})
    const transform = assetTransform(asset, assetVar, variation)
    query.a = `${asset.location}$${transform}`
  }

  return `${host}/pi?${stringify(query)}`
}

export const sampleProductImageUrl = (image_server, assets, productTypes) => {
  const {asset} = assets.find(({asset}) => asset.variations.length) || {}

  if (!asset) {
    return ''
  }

  const assetVariations = {}

  asset.variations.forEach((v) => (assetVariations[v.asset_variation_id] = v))

  const products = flattenDeep(
    productTypes.map((type) => type.product_groups.map((g) => g.products))
  )

  const product = products.find((p) =>
    p.variations.find((v) => assetVariations[v.variation.id])
  )

  const {variation} = product.variations.find(
    (v) => assetVariations[v.variation.id]
  )

  return productImageUrl(
    image_server,
    product,
    asset,
    variation,
    productImages(product, variation, sortBy(product.skus, 'color_sort')[0])[0]
  )
}

/**
 * To determine a single "store" (for a team, athlete, etc) that an asset belongs to.
 * Matches the order of precedence defined in `asset` function from `prefixes.js`
 * @param {Object} asset

 * @returns {{storeType: 'team_revision'|'athlete_revision'|'event'|'league', id: number} | undefined}

 */

export const determineStoreForAsset = (asset) => {
  if (asset.labelEvent) {
    return {storeType: 'event', id: asset.labelEvent.id}
  }

  if (asset.labelTeamRevision) {
    return {storeType: 'team_revision', id: asset.labelTeamRevision.id}
  }

  if (asset.labelAthleteRevision) {
    return {storeType: 'athlete_revision', id: asset.labelAthleteRevision.id}
  }

  if (asset.labelLeague) {
    return {storeType: 'league', id: asset.labelLeague.id}
  }
}
