//@ts-check

import {css} from 'glamor'
import flatten from 'lodash/flatten'
import React, {Fragment} from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {connect} from 'react-redux'
import Link from 'react-router/lib/Link'
import {fetchLeagues} from '../redux/actions/league'
import Helmet from './helmet'
import Icon, {icons} from './icon'
import {media} from '../bundle/client/style'
import SignUpTile from './signup_tile'

// See https://css-tricks.com/snippets/css/sticky-footer/ for implementation
// details.
const style = {
  html: css({
    height: '100%'
  }),
  body: css({
    height: '100%',
    '& #app': {
      height: '100%'
    }
  }),
  footer: css({
    backgroundColor: '#333',
    color: '#fff',
    padding: '50px 0 20px',
    ...media.xs({padding: '20px'}),
    '& a': {
      color: '#fff'
    },
    '& .text-muted a': {
      color: '#727272'
    },
    '& h4': css({
      textTransform: 'uppercase',
      fontWeight: '500',
      marginTop: '30px',
      fontSize: '14px',
      letterSpacing: '1px'
    }),
    '& ol li': css({
      margin: '25px 0'
    }),
    '& p': {
      display: 'block',
      marginTop: '50px'
    }
  }),
  email: css({
    '& input': {
      color: '#fff',
      background: 'transparent !important',
      border: '1px solid #ccc',
      borderRight: 0,
      '&:focus + .input-group-btn button': {
        borderColor: 'rgb(102, 175, 233) !important'
      },
      '&:disabled': {
        opacity: 0.65
      }
    },
    '& .input-group-btn': {
      '& button': {
        background: 'transparent !important',
        border: '1px solid #ccc',
        borderLeft: 'none',
        height: '34px', // ensures no tom-foolery
        marginLeft: '0 !important', // ensures no border overlap
        outline: 'none !important',
        transition: 'border-color ease-in-out .15s',
        '&:active:focus, &:hover': {
          borderColor: '#ccc'
        }
      }
    },
    '& i': {
      color: '#fff'
    }
  }),
  giveaway: css({
    '& h2': {
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    '& img': {
      height: '35px',
      verticalAlign: 'bottom'
    }
  }),
  gray: css({color: '#999'}),
  socialMedContainer: css({
    display: 'flex',
    gap: '0.5rem'
  }),
  footerLinks: css({
    '& > li > a': {
      textDecoration: 'none'
    }
  }),
  disclaimerLink: css({
    textDecoration: 'none'
  })
}

const Footer = (props) => {
  return (
    <Fragment>
      <Helmet>
        <html {...style.html} />
        <body {...style.body} />
      </Helmet>
      <div {...style.footer} data-testid="app-footer" id="footer">
        <Container>
          <Row>
            <Col md={12} lg={5} xl={3}>
              <SignUpTile />
            </Col>
            <Col xs={0} lg={1} />
            <Col xs={12} lg={2}>
              <h4>Help</h4>
              <ol className="list-unstyled" {...style.footerLinks}>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/order/status">Order status</Link>
                </li>
                <li>
                  <a href="https://dash12.comm100.io/kb/10002809/b4e65d96-8978-4225-94d7-1010b4a8dcce/a/f3ef5269-2737-4200-b5e0-b7bc5a3045a1/What-is-your-return-policy">
                    Delivery & returns
                  </a>
                </li>
                <li>
                  <a href="https://dash12.comm100.io/kb/10002809/b4e65d96-8978-4225-94d7-1010b4a8dcce">
                    FAQ
                  </a>
                </li>
              </ol>
            </Col>
            <Col xs={12} lg={2}>
              <h4>About Us</h4>
              <ol className="list-unstyled" {...style.footerLinks}>
                <li>
                  <Link to="/about">Our story</Link>
                </li>

                <li>
                  <Link to="/reviews">Reviews</Link>
                </li>

                <li>
                  <Link to="/b">Blog</Link>
                </li>
                <li>
                  <Link to="/site/in/the/news">In The News</Link>
                </li>
              </ol>
            </Col>
            <Col xs={12} lg={2}>
              <h4>Info</h4>
              <ol className="list-unstyled" {...style.footerLinks}>
                <li>
                  <Link to="/l/college-athletes">Vintage Athletes</Link>
                </li>
                <li>
                  <Link to="/site/partners/affiliates">
                    Partners & Affiliates
                  </Link>
                </li>
                <li>
                  <Link to="/terms">Terms of use</Link>
                </li>
              </ol>
            </Col>
            <Col xs={12} lg={2} className="d-md-none d-lg-block">
              <h4>Social Media</h4>
              <Row>
                <Col>
                  <ul className="list-inline" {...style.socialMedContainer}>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCrTXJvuf-jkoBijnkbOf_Bw"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icon icon={icons.youtube} size="2x" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.pinterest.com/vintagebrandusa"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icon icon={icons.pinterest} size="2x" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/vintagebrand/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icon icon={icons.linkedIn} size="2x" />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p>
                {props.showDisclaimer && (
                  <span>
                    Our products are not affiliated with, licensed, sponsored,
                    or endorsed by any college, team, league, event or licensing
                    entity. The work of art incorporated into this product is
                    either an original by Vintage Brand, or it has been copied
                    from a work that was not created by the school/team and is
                    in the public domain. Vintage Brand&reg; LLC, products are
                    not authorized or sponsored by any college, team, league, or
                    event. All products are designed by Vintage Brand&reg; and
                    manufactured for Vintage Brand&reg;.{' '}
                  </span>
                )}
                <a href="/privacy" {...style.disclaimerLink}>
                  Privacy policy
                </a>{' '}
                |{' '}
                <a href="/cal" {...style.disclaimerLink}>
                  CAL Disclosure
                </a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

Footer.initialize = ({dispatch}) =>
  dispatch(fetchLeagues({order: 'sort.asc'}, true))

/**
 *
 * @param {Array<Object>} leagues
 * @param {{athleteRevisionId: string, athleteRef: string, leagueId: string, leagueRef: string}} param1
 * @returns boolean
 */
const isAthleteRoute = (
  leagues,
  {athleteRevisionId, athleteRef, leagueId, leagueRef}
) => {
  if (athleteRevisionId || athleteRef) {
    return true
  }

  const athleteLeagues = leagues.filter((item) => item.type === 'athlete')
  const athleteRefs = flatten(athleteLeagues.map((item) => item.refs))

  if (
    leagueId &&
    athleteLeagues.some((item) => item.id === parseInt(leagueId, 10))
  ) {
    return true
  }

  if (leagueRef && athleteRefs.some((item) => item.ref === leagueRef)) {
    return true
  }
}

const stateToProps = (state, props) => {
  const {params, location} = props

  const {
    league_navigation: {items: leagues}
  } = state

  const isAthleteLeague = isAthleteRoute(leagues, {
    athleteRevisionId: location?.query?.ar,
    athleteRef: params?.athleteRef,
    leagueId: location?.query?.l,
    leagueRef: params?.league || (params?.splat && params.splat.split('/')[0])
  })

  return {showDisclaimer: !isAthleteLeague}
}

export default connect(stateToProps)(Footer)
