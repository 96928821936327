import browserHistory from 'react-router/lib/browserHistory'
import times from 'lodash/times'
import {createActions} from '@/redux'
import {createAddress, updateAddress} from './address'
import {extended as ASSET} from '@/shared/queries/asset'
import moment from 'moment'
import axios from 'axios'
import bankersRounding from '@/lib/helpers/round'
import {country} from './index'
import {ALLOWED_COUNTRIES} from '@/lib/sequelize/constants'
import {pushGTMData} from '@/bundle/client/analytics/pushGTMData'
import {generateAddToCartGALegacyGTMData} from '@/bundle/client/analytics/GALegacy/generateAddToCartGALegacyGTMData'
import {pushAttentiveData} from '@/bundle/client/analytics/pushAttentiveData'
import {generateAttentiveEventData} from '@/bundle/client/analytics/Attentive/generateAttentiveEventData'
import {generateRemoveFromCartGA4GTMData} from '@/bundle/client/analytics/GA4/generateRemoveFromCartGA4GTMData'
import {generateRemoveFromCartGALegacyGTMData} from '@/bundle/client/analytics/GALegacy/generateRemoveFromCartGALegacyGTMData'

export const cart = createActions('cart')
export const cartItem = createActions('cart_item')
export const cartSuggestions = createActions('cart_suggestions')

export const addToCart =
  (
    cart,
    product,
    sku,
    variation,
    variations,
    quantity,
    SERVER_URLS,
    discount,
    asset
  ) =>
  (dispatch) => {
    const {price} = product
    const discountPercentage = discount ? discount.value : 0

    const calculatedPrice = bankersRounding(
      price - bankersRounding(price * discountPercentage)
    )

    return dispatch(
      cartItem.create({
        options: {
          body: times(quantity, () => ({
            cart_id: cart.id,
            product_id: product.id,
            product_sku_id: sku.id,
            asset_variation_id: variation.id,
            asset_variations_id: variations.id
          }))
        }
      })
    ).then(({err}) => {
      if (err) {
        console.error('addToCart', err)
        throw err
      } else {
        Promise.all([
          pushGTMData(
            generateAddToCartGALegacyGTMData({
              productCategory: (product.product_group || product.group).label,
              productId: sku.id,
              productPrice: product.price,
              productQuantity: quantity,
              productVariant: variations.id
            })
          ).then((data) => data),
          pushAttentiveData(
            'addToCart',
            generateAttentiveEventData({
              cart: {...cart, items: []},
              product: {...product, price: calculatedPrice},
              asset,
              variation,
              variations,
              discount,
              sku,
              quantity: parseInt(quantity, 10)
            })
          ).then((data) => data)
        ]).then((data) => data)
        return dispatch(fetchCartWithProductsAndShippingFromApi())
      }
    })
  }

export const createCartAndAdd =
  (
    product,
    sku,
    variation,
    variations,
    quantity,
    SERVER_URLS,
    discount,
    asset
  ) =>
  (dispatch) =>
    dispatch(cart.create({options: {body: {}}})).then(
      /** @param {App.Actions.ApiActionResponse<App.Vintage.Synthetic.Cart>} params */
      ({err, body: carts = []}) => {
        if (err) {
          if (err.response && err.response.status === 409) {
            return dispatch(fetchCartWithProductsAndShippingFromApi()).then(
              /** @param {{body: App.Vintage.Synthetic.Cart[]}} params */
              ({body: carts = []}) => {
                const [cart] = carts
                if (cart) {
                  return dispatch(
                    addToCart(
                      cart,
                      product,
                      sku,
                      variation,
                      variations,
                      quantity,
                      SERVER_URLS,
                      discount,
                      asset
                    )
                  )
                } else {
                  const config = {
                    url: `${SERVER_URLS}/api/cookie`,
                    method: 'DELETE',
                    data: {name: 'trzr'}
                  }
                  axios
                    .request(config)
                    .then(() => {
                      const message =
                        'Unable to add item to cart. Please refresh the page and try again.'
                      console.error('createCartAndAdd', message)
                      throw new Error(message)
                    })
                    .catch((err) => {
                      const message = `Unable to add item to cart. ${err} Please refresh the page and try again.`
                      console.error('createCartAndAdd', message, err)
                      throw new Error(message)
                    })
                }
                return cart
              }
            )
          } else {
            const message = 'Unable to add item to cart'
            console.error('createCartAndAdd', message, err)
            throw new Error(message)
          }
        }

        const [cart] = carts

        if (cart) {
          return dispatch(
            addToCart(
              cart,
              product,
              sku,
              variation,
              variations,
              quantity,
              SERVER_URLS,
              discount,
              asset
            )
          )
        } else {
          const message = 'Unable to add item to cart'
          console.error('createCartAndAdd', message)
          throw new Error(message)
        }
      }
    )

/**
 * Fetches the cart with products and shipping from the API.
 * @param {boolean} [cached=false]
 * @returns {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Synthetic.Cart>>>}
 */
export const fetchCartWithProductsAndShippingFromApi =
  (cached = false) =>
  /** @type {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Synthetic.Cart>>>} */
  (dispatch) => {
    // This date limit allows the query to not have to fetch all orders
    // since the beginning of Vintage's launch. This should be set with
    // the value at which a cart is unlikely to be reused or has been abandoned.
    const dataLimit = moment().subtract(3, 'months').toISOString()

    return dispatch(
      cart.get({
        query: {
          order: 'id.desc',
          limit: 1,
          select: `
        *,
        shipping(*),
        shippingAddress:address!shipping_address_id(*,country(*)),
        billingAddress:address!billing_address_id(*,country(*)),
        items:cart_item(
          id,
          sku:product_sku!cart_item_product_sku_id_fkey(*,color_swatch(*)),
          product(
            *,
            product_group(
              *,
              products:product(
                *,
                variations:product_asset_variations(
                  variation:asset_variation(*)
                ),
                images:product_image(*)
              )
            ),
            product_asset_variations(*),
            skus:product_sku(size)
          ),
          asset_variation(*),
          asset_variations(id,asset(${ASSET}))
        )
      `.replace(/\s/g, ''),
          and: `(created_at.gte.${dataLimit}))`
        },
        merge: {},
        cached
      })
    )
  }

/**
 *
 * @param {App.Vintage.Synthetic.Cart} cart
 * @param {App.Vintage.Synthetic.CartItem[]} items
 * @param {App.Vintage.Base.Discount[]} discounts
 * @returns {function(*): Promise<Awaited<unknown>[]>}
 */
export const removeFromCart = (cart, items, discounts) => (dispatch) =>
  Promise.all([
    // Remove item from cart
    dispatch(
      cartItem.delete({
        query: {
          id: `in.(${items.map(({id}) => id).join(',')})`,
          cart_id: `eq.${cart.id}`
        },
        merge: {}
      })
    ),
    pushGTMData(
      generateRemoveFromCartGA4GTMData({
        discounts,
        items
      })
    ).then((data) => data),
    pushGTMData(
      generateRemoveFromCartGALegacyGTMData({
        items
      })
    ).then((data) => data)
  ]).then((data) => data)

/**
 * @param {number} id
 * @param {number} addressId
 * @param {module:stripe.Stripe.Token} stripeToken
 * @param {boolean} [noRedirect=false]
 * @returns {import('redux-thunk').ThunkAction<Promise<unknown>>}
 */
export const updateCartBilling =
  (id, addressId, stripeToken, noRedirect = false) =>
  /** @type {import('redux-thunk').ThunkAction<Promise<unknown>>} */
  (dispatch) =>
    dispatch(
      cart.update({
        query: {id: `eq.${id}`},
        options: {
          body: {
            billing_address_id: addressId,
            card_last_four: stripeToken?.card?.last4 || stripeToken?.type,
            stripe_token: stripeToken?.id
          }
        },
        merge: {id}
      })
    )
      .then(() => dispatch(fetchCartWithProductsAndShippingFromApi()))
      .then(() => {
        if (!noRedirect) {
          browserHistory.push('/checkout/review')
        }
      })

/**
 * @param {number} id
 * @param {App.Vintage.Base.Address} address
 * @param {module:stripe.Stripe.Token} stripeToken
 * @param {boolean} [noRedirect=false]
 * @returns {import('redux-thunk').ThunkAction<Promise<unknown>>}
 */
export const createCartBillingAddressAndToken =
  (id, address, stripeToken, noRedirect = false) =>
  /** @type {import('redux-thunk').ThunkAction<Promise<unknown>>} */
  (dispatch) =>
    dispatch(createAddress(address)).then(({body: addresses = []}) => {
      const [address] = addresses
      if (address) {
        return dispatch(
          updateCartBilling(id, address.id, stripeToken, noRedirect)
        )
      }
    })

/**
 * @param {number} id
 * @param {number} addressId
 * @param {App.Vintage.Base.Address} address
 * @param {module:stripe.Stripe.Token} stripeToken
 * @param {boolean} [noRedirect=false]
 * @returns {import('redux-thunk').ThunkAction<Promise<unknown>>}
 */
export const updateCartBillingAddressAndToken =
  (id, addressId, address, stripeToken, noRedirect = false) =>
  /** @type {import('redux-thunk').ThunkAction<Promise<unknown>>} */
  (dispatch) =>
    dispatch(updateAddress(addressId, address)).then(
      /** @param {App.Actions.ApiActionResponse<App.Vintage.Base.Address>} param */
      ({body: addresses = []}) => {
        if (addresses.length) {
          return dispatch(
            updateCartBilling(id, addressId, stripeToken, noRedirect)
          )
        }
      }
    )

export const fetchCartSuggestions = (args) => (dispatch) => {
  return dispatch(
    cartSuggestions.get({
      query: {
        stores: JSON.stringify(args.stores),
        revealedAssetIds: JSON.stringify(args.revealedAssetIds)
      }
    })
  )
}

/**
 * Fetches the countries from the API.
 * @param {boolean} [cached=false]
 * @returns {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Base.Country>>>}
 */
export const fetchCountries =
  (cached = false) =>
  /** @type {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Base.Country>>>} */
  (dispatch) =>
    dispatch(
      country.get({
        query: {
          select: '*,state_province(*)',
          code: `in.(${ALLOWED_COUNTRIES.join(',')})`
        },
        cached
      })
    )
