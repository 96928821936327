import omit from 'lodash/omit'
import global from '../../../redux/reducers/filter'
import {combineReducers} from 'redux'
import {helpers} from '@teamrazr/redux'

const meta = helpers.createApiReducer('meta', 'filter_meta')
const page = helpers.createApiReducer('page', 'filter_page')

export default combineReducers({
  meta: (state, action) => {
    const result = meta(state, action)
    return {...result, items: omit(result.items, 'page')}
  },
  page: (state, action) => {
    switch (action.type) {
      case 'GET_FILTER_META_SUCCESS': {
        const result = meta(state, action)
        return {...result, items: result.items.page, next: result.items.next}
      }

      case 'GET_FILTER_PAGE_SUCCESS': {
        const result = page(state, action)
        return {
          ...result,
          items: state.items.concat(action.body.page),
          next: action.body.next
        }
      }

      default:
        return page(state, action)
    }
  },
  eventGroup: helpers.createApiReducer('eventGroup', 'filter_event_group'),
  eventType: helpers.createApiReducer('eventType', 'filter_event_type'),
  global,
  league: helpers.createApiReducer('league', 'filter_league'),
  productType: helpers.createApiReducer('productType', 'filter_product_type'),
  ref: helpers.createApiReducer('ref', 'filter_meta_ref'),
  team: helpers.createApiReducer('team', 'filter_team'),
  teamRevision: helpers.createApiReducer(
    'teamRevision',
    'filter_team_revision'
  ),
  athleteRevision: helpers.createApiReducer(
    'athleteRevision',
    'filter_athlete_revision'
  )
})
