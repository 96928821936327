//@ts-check

import {filterObject} from '../../shared/object'
import {helpers} from '@teamrazr/redux'
import * as qs from '../../shared/query'
import * as labels from '../../shared/labels'

let Algoliasearch

export const indices = {
  team_revision: {
    hitToDisplayName: (hit) => hit.name,
    hitToRedirectUrl: (hit) => `/p?${qs.stringify({tr: hit.id})}`,
    options: {
      facetFilters: [
        ['active:true'],
        ['team.active:true'],
        ['league.active:true']
      ]
    }
  },
  event: {
    hitToDisplayName: (hit) => hit.label,
    hitToRedirectUrl: (hit) => `/p?${qs.stringify({eg: hit.event_group_id})}`,
    options: {
      facetFilters: [['event_group.event_type.league.active:true']]
    }
  },
  athlete_revision: {
    hitToDisplayName: (hit) => labels.athlete(hit.athlete),
    hitToRedirectUrl: (hit) =>
      hit.athlete.refs && hit.athlete.refs.length > 0
        ? `/site/athlete/${hit.athlete.refs[0].ref}`
        : undefined,
    options: {
      facetFilters: [
        ['active:true'],
        ['athlete.active:true'],
        ['league.active:true']
      ]
    }
  }
}

const searchAction = helpers.createAction('SEARCH', 'query', 'source')
const searchSuccessAction = helpers.createAction(
  'SEARCH_SUCCESS',
  'items',
  'query'
)
const searchErrorAction = helpers.createAction('SEARCH_ERROR', 'err')

export const search =
  (query, hitsPerPage = 10, cached, source) =>
  async (dispatch, getState) => {
    const {env, search} = getState()
    if (!query || (cached && query === search.query)) {
      return Promise.resolve()
    }

    dispatch(searchAction(query, source))

    if (!Algoliasearch) {
      Algoliasearch = (
        await import(
          /* webpackChunkName: "algolia_search" */ 'algoliasearch/lite'
        )
      ).default
    }

    const client = Algoliasearch(
      env.ALGOLIA_APP_ID,
      env.ALGOLIA_PUBLISHABLE_KEY
    )

    try {
      const {results = {}} =
        (await client.multipleQueries(
          Object.keys(indices).map((indexName) => ({
            indexName,
            query,
            params: filterObject({...indices[indexName].options, hitsPerPage})
          }))
        )) || {}

      return dispatch(searchSuccessAction(results, query))
    } catch (err) {
      return dispatch(searchErrorAction(err))
    }
  }
