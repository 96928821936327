import bankersRounding from '../../../../lib/helpers/round'

export const generateRemoveFromCartGA4GTMData = ({discounts, items}) => {
  const discount = discounts && discounts.length > 0 ? discounts[0] : null

  let totalValue = 0
  const gaItems = items.map((item) => {
    const {product} = item
    const {price} = product
    const code = discount ? discount.code : ''
    const discountPercentage = discount ? discount.value : 0

    const calculatedPrice = bankersRounding(
      price - bankersRounding(price * discountPercentage)
    )

    const discountValue = bankersRounding(price - calculatedPrice)

    totalValue += calculatedPrice
    return {
      item_id: item.product.id,
      item_name: item.product.label,
      discount: discountValue,
      coupon: code,
      price: price
    }
  })

  return {
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'USD',
      value: totalValue,
      items: gaItems
    }
  }
}
