import React from 'react'
import {css} from 'glamor'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const styles = {
  container: css({
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Oswald',
    justifyContent: 'center'
  }),
  percentage: css({
    color: '#19a449',
    fontSize: '36px',
    fontWeight: 700,
    marginBottom: '8px',
    marginTop: '0px !important',
    textAlign: 'center'
  }),
  title: css({
    color: '#333',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 1,
    marginTop: '16px !important',
    marginBottom: '2px !important',
    textTransform: 'uppercase'
  }),
  descriptionText: css({
    color: '#989898',
    fontFamily: 'Rubik',
    marginBottom: '0px',
    marginLeft: '16px',
    marginRight: '16px',
    marginTop: '0px !important',
    textAlign: 'center'
  }),
  signUpButton: css({
    backgroundColor: '#19a449',
    border: 'none',
    color: '#fff',
    marginBottom: '16px',
    marginTop: '16px',
    textTransform: 'uppercase',
    width: '220px'
  })
}

const SignUpTile = () => {
  const details = {
    title: 'Enjoy Big Perks',
    percentage: 'JOIN THE TEAM',
    description:
      '*Sign up to be the first to hear about new products, designs, and promotions'
  }

  const {description, percentage, title} = details
  const descriptionText = description.replace('percentage', percentage)

  return (
    <div {...styles.container}>
      <Row>
        <Col sm={12} md={12} lg={12}>
          {' '}
          <p {...styles.title}>{title}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <p {...styles.percentage}>{percentage}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <p {...styles.descriptionText}>{descriptionText}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Button
            variant="success"
            {...styles.signUpButton}
            onClick={() => {
              location.href =
                'https://vintagebrand.attn.tv/p/uOs/landing-page?utm_campaign=241021_hockey&utm_content=241021_hockey&utm_medium=email_action&utm_source=customer.io'
            }}
          >
            Sign Up
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default SignUpTile
