import {createActions} from '../../../redux'

export const assetVariations = createActions('asset_variations')
export const country = createActions('country')
export const event = createActions('event')
export const eventType = createActions('event_type')
export const league = createActions('league')
export const state = createActions('state')
export const taxRate = createActions('tax_rate')
export const team = createActions('team')
export const teamRevision = createActions('team_revision')
