import {css} from 'glamor'

export const breakpointConstants = {
  xs: {max: 575},
  sm: {min: 576, max: 767},
  md: {min: 768, max: 991},
  lg: {min: 992, max: 1199},
  xl: {min: 1200},
  xxl: {min: 1400}
}

export const breakpoints = {
  xs: `(max-width: ${breakpointConstants.xs.max}px)`,
  sm: `(max-width: ${breakpointConstants.sm.max}px) and (min-width: ${breakpointConstants.sm.min}px)`,
  md: `(max-width: ${breakpointConstants.md.max}px) and (min-width: ${breakpointConstants.md.min}px)`,
  lg: `(max-width: ${breakpointConstants.lg.max}px) and (min-width: ${breakpointConstants.lg.min}px)`,
  xl: `(min-width: ${breakpointConstants.xl.min}px)`,
  xxl: `(min-width: ${breakpointConstants.xxl.min}px)`
}

export const media = {
  xs: (rules) => ({
    [`@media ${breakpoints.xs}`]: rules
  }),
  sm: (rules) => ({
    [`@media ${breakpoints.sm}`]: rules
  }),
  md: (rules) => ({
    [`@media ${breakpoints.md}`]: rules
  }),
  lg: (rules) => ({
    [`@media ${breakpoints.lg}`]: rules
  }),
  xl: (rules) => ({
    [`@media ${breakpoints.xl}`]: rules
  }),
  xxl: (rules) => ({
    [`@media ${breakpoints.xxl}`]: rules
  }),
  mobile: (rules) => ({
    [`@media ${breakpoints.xs}`]: rules,
    [`@media ${breakpoints.sm}`]: rules,
    [`@media ${breakpoints.md}`]: rules
  }),
  desktop: (rules) => ({
    [`@media ${breakpoints.lg}`]: rules,
    [`@media ${breakpoints.xl}`]: rules,
    [`@media ${breakpoints.xxl}`]: rules
  })
}

export const style = {
  flex: css({display: 'flex'}),
  green: css({color: '#19a449'}),
  savingsGreen: css({
    color: '#16883e'
  }),
  list: css({
    margin: 0,
    '& h2': {
      margin: 0
    },
    '& .list-group-item': {
      padding: '20px 15px',
      borderRight: 'none',
      borderLeft: 'none',
      borderBottom: 'none',
      textTransform: 'uppercase'
    },
    '& i': {
      color: '#ccc'
    }
  }),
  breadcrumb: css({
    margin: 0,
    textTransform: 'uppercase',
    width: '100%',
    '@media (max-width: 767px)': {
      fontSize: '12px'
    },
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }),
  title: css({
    textTransform: 'uppercase',
    marginTop: '20px'
  }),
  spacing: css({
    marginTop: '15px',
    marginBottom: '15px'
  }),
  media: css({
    textAlign: 'center'
  }),
  headerMargins: css({
    marginTop: '20px',
    marginBottom: '10px'
  }),
  scrollList: css({
    display: 'flex',
    gap: '15px',
    textAlign: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    scrollSnapType: 'mandatory',
    scrollSnapPointsX: 'repeat(100%)',
    '> div': {
      scrollSnapAlign: 'start',
      width: '170px',
      display: 'inline-block',
      float: 'none',
      '@media (min-width: 768px)': {
        width: '220px'
      }
    }
  }),
  pointer: css({cursor: 'pointer'}),
  seoCopy: css({
    position: 'relative',
    '& > div': {
      maxHeight: '100px',
      overflow: 'auto',
      paddingBottom: '20px'
    },
    '& h5': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      marginBottom: '20px'
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '20px',
      background: 'linear-gradient(rgba(255,255,255,0), #fff)'
    }
  })
}
