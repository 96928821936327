/**
 *
 * @param {string} method
 * @param {App.Attentive.AttentiveEvent} data
 * @returns {Promise<Awaited<App.Attentive.AttentiveEvent>>}
 */
export const pushAttentiveData = (method, data) => {
  // console.log('pushAttentiveData', method, data)
  return new Promise((resolve) => {
    const {...options} = {...data}
    pushOrFallback(method, options)
    resolve(options)
  })
}

/**
 *
 * @param {string} method
 * @param {AttentiveEvent} options
 */
// eslint-disable-next-line no-unused-vars
const pushOrFallback = (method, options) => {
  try {
    if (window?.attentive?.analytics[method]) {
      try {
        // console.log(
        //   'window.attentive.analytics[method](options)',
        //   method,
        //   options
        // )
        window.attentive.analytics[method](options)
      } catch (err) {
        console.warn(err)
      }
    } else {
      const optionsString = JSON.stringify(options)
      const js = document.createElement('script')
      js.innerHTML = `
      ((options) => {
        try {
          if (attentive?.analytics?.${method}) {
            attentive.analytics.${method}(options);
          }
        } catch (err) {}
      })(${optionsString})`
      const body =
        document.body ||
        document.querySelector('body') ||
        document.getElementsByTagName('body')[0]
      body.appendChild(js)
    }
  } catch (err) {
    console.warn(err)
  }
}
