const bankersRounding = (num, decimalPlaces = 2) => {
  const decimalMultiplier = Math.pow(10, decimalPlaces)
  const numMultiplied = (decimalPlaces ? num * decimalMultiplier : num).toFixed(
    8
  ) // Avoid rounding errors
  const numMultipliedWithoutDecimals = Math.floor(numMultiplied) // drop the decimal places
  const leftOverDecimals = numMultiplied - numMultipliedWithoutDecimals
  const e = 1e-8 // Allow for rounding errors in leftOverDecimals
  const roundedNum =
    leftOverDecimals > 0.5 - e && leftOverDecimals < 0.5 + e
      ? numMultipliedWithoutDecimals % 2 == 0
        ? numMultipliedWithoutDecimals
        : numMultipliedWithoutDecimals + 1
      : Math.round(numMultiplied)
  return parseFloat((roundedNum / decimalMultiplier).toFixed(2))
}

export default bankersRounding
