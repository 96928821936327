import {useDeferredOnChange} from '@standardlabs/react-hooks'
import {css} from 'glamor'
import flattenDeep from 'lodash/flattenDeep'
import uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'
import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import {useDispatch, useSelector} from 'react-redux'
import browserHistory from 'react-router/lib/browserHistory'
import {indices, search} from '../redux/actions/search'
import Icon, {icons} from './icon'
import Offcanvas from 'react-bootstrap/Offcanvas'

const MAX_RESULTS = 10

const style = {
  container: css({position: 'relative'}),
  desktopForm: css({
    '& .form-control': {
      height: '35px',
      borderRadius: '18px'
    }
  }),
  icon: css({color: '#999'}),
  dropdown: css({
    '& .dropdown-toggle': {boxShadow: 'none !important'},
    '& .dropdown-menu': {margin: 0},
    '& .form-group': {marginBottom: 0}
  }),
  menu: css({
    minWidth: '100%',
    '& em': {
      fontWeight: '500',
      fontStyle: 'normal'
    }
  }),
  mobileForm: css({
    width: '100%'
  }),
  offcanvas: {
    container: css({width: '100% !important'}),
    header: css({borderBottom: '1px solid #e5e5e5'}),
    searchIcon: css({backgroundColor: 'transparent', border: 'none'}),
    input: css({border: 'none', marginBottom: 0}),
    body: css({backgroundColor: '#f2f2f2'})
  },
  listGroup: css({
    marginBottom: 0,
    '& .list-group-item': {
      textAlign: 'left',
      backgroundColor: 'inherit',
      border: 0,
      '& em': {
        fontWeight: '500',
        fontStyle: 'normal'
      },
      '&:hover': {
        backgroundColor: '#f5f5f5'
      }
    }
  }),
  inputBox: css({
    borderRight: 'none',
    marginBottom: 0,
    '&:focus': {
      borderColor: '#66afe9'
    },
    '&:focus + #search-icon': {
      borderColor: '#66afe9'
    }
  }),
  inputIconBox: css({
    borderTopRightRadius: '18px',
    borderBottomRightRadius: '18px',
    backgroundColor: 'transparent'
  })
}

const Search = ({
  location: {
    query: {q = ''}
  },
  onHide,
  show
}) => {
  const [closed, setClosed] = useState(true)
  const onFocus = useCallback(() => setClosed(false), [setClosed])

  // This guy debounces updates to input but exposes the current value AND the
  // debounced value.
  const [input, onChangeInput, setInput] = useDeferredOnChange(q, 150)

  // Synchronize search state with deferred input value
  const dispatch = useDispatch()
  useEffect(() => {
    input.deferred && dispatch(search(input.deferred))
  }, [input.deferred, dispatch])

  const inputRef = useRef(null)
  const onSubmit = useCallback(
    (e) => {
      e && e.preventDefault()

      if (input.value) {
        setClosed(true)
        onHide && onHide()
        inputRef.current.blur()
        browserHistory.push(`/search?q=${encodeURIComponent(input.value)}`)
      }
    },
    [onHide, input.value, setClosed]
  )

  const onResultSelected = useCallback(
    (input, redirectUrl) => {
      setInput(input)
      setClosed(true)
      onHide && onHide()
      browserHistory.push(redirectUrl)
    },
    [setInput, setClosed, onHide]
  )

  const items = useSelector((state) => state.search.items)

  const suggestions = items.map(({index, hits = []}) =>
    uniqBy(
      hits
        .map((hit) => {
          return {
            redirectUrl: indices[index].hitToRedirectUrl(hit),
            html: indices[index].hitToDisplayName(hit),
            match: indices[index].hitToDisplayName(hit)
          }
        })
        .filter((item) => item.redirectUrl),
      'match'
    )
  )

  // Take portion from each index up to max results
  let results = []
  let count = 0
  let done = false
  while (!done) {
    const curCount = count
    suggestions.forEach((list, i) => {
      results[i] = results[i] || []
      if (list.length) {
        results[i].push(list.shift())
        count++
      }
    })

    if (count === MAX_RESULTS || curCount === count) {
      done = true
    }
  }
  results = flattenDeep(results)

  const open = !closed && !!input.value && !!results.length
  const onBlur = (e) => {
    if (e.relatedTarget !== null) {
      return
    }

    setClosed(true)
  }

  return (
    <Fragment>
      <Dropdown
        id="search-dropdown"
        onFocus={() => setClosed(false)}
        onBlur={(e) => onBlur(e)}
        show={closed ? false : true}
        {...style.dropdown}
      >
        <Form className="search" onSubmit={onSubmit} {...style.desktopForm}>
          <InputGroup>
            <FormControl
              className="search-control"
              ref={(el) => (inputRef.current = el)}
              onChange={onChangeInput}
              onFocus={onFocus}
              placeholder="Search"
              type="search"
              value={input.value}
              {...style.inputBox}
            />
            <InputGroup.Text id="search-icon" {...style.inputIconBox}>
              <Icon size="sm" icon={icons.search} {...style.icon} />
            </InputGroup.Text>
          </InputGroup>
        </Form>
        <Dropdown.Menu {...style.menu} className={!open ? 'd-none' : ''}>
          {results.map(({redirectUrl, html, match}, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => onResultSelected(match, redirectUrl)}
              dangerouslySetInnerHTML={{__html: html}}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Offcanvas show={show} onHide={onHide} {...style.offcanvas.container}>
        <Offcanvas.Header closeButton {...style.offcanvas.header}>
          <Form onSubmit={onSubmit} {...style.mobileForm}>
            <InputGroup>
              <InputGroup.Text id="search-icon" {...style.offcanvas.searchIcon}>
                <Icon icon={icons.search} {...style.icon} />
              </InputGroup.Text>
              <FormControl
                className="search-control"
                ref={(el) => (inputRef.current = el)}
                onChange={onChangeInput}
                onFocus={onFocus}
                placeholder="Search"
                type="search"
                value={input.value}
                {...style.offcanvas.input}
              />
            </InputGroup>
          </Form>
        </Offcanvas.Header>

        <Offcanvas.Body {...style.offcanvas.body}>
          <ListGroup {...style.listGroup}>
            {results.map(({redirectUrl, html, match}, i) => (
              <ListGroupItem
                key={i}
                onClick={() => onResultSelected(match, redirectUrl)}
                dangerouslySetInnerHTML={{__html: html}}
                as="button"
              />
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

Search.propTypes = {
  location: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool
}

export default Search
