import {createActions} from '../index'

export const discountHeader = createActions(
  'discount',
  ['discount_header'],
  'discount_header'
)

export const fetchDiscountHeaders = (cached) =>
  discountHeader.get({
    query: {
      valid: 'eq.true',
      header: 'not.is.null',
      private: 'eq.false',
      select: '*,discount_type(*),discount_product_group(*),discount_league(*)'
    },
    cached
  })
