// This import must be first to allow Glamor CSS overrides
import '../styles/theme/main.scss'

import {helpers} from '@teamrazr/redux'
import {rehydrate} from 'glamor'
import React from 'react'
import {hydrateRoot} from 'react-dom/client'
import match from 'react-router/es/match'
import browserHistory from 'react-router/lib/browserHistory'
import {applyMiddleware, compose, createStore} from 'redux'
import {thunk} from 'redux-thunk'
import Root from '../components/root'

// Rehydrate must be called before any components that use glamor are loaded.
// Since es6 does not guarantee load order for `import` syntax we must use
// a separate function and a require call (wherever init.js is used). See:
// https://github.com/threepointone/glamor/issues/37#issuecomment-257831193
export const rehydrateCss = () => {
  rehydrate(window.__INIT_CSS__)
}

export const renderApp = (reducers, routes) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    reducers,
    window.__INIT_STATE__,
    composeEnhancers(applyMiddleware(thunk, helpers.fetchMiddleware))
  )

  // Calling match makes sure any async routes are loaded before we hydrate
  match(
    {location, history: browserHistory, routes: routes(store)},
    (err, redirect, props) => {
      hydrateRoot(
        document.getElementById('app'),
        <Root {...props} store={store} />
      )
    }
  )
}
