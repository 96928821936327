import contentful from './contentful'
import error from './error'
import filter from './filter'
import search from './search'
import {helpers, reducers} from '@teamrazr/redux'

const slim = {
  account: reducers.identity,
  cookie: reducers.identity,
  env: reducers.identity,
  dataDog: reducers.identity
}

export default slim

export const extended = {
  ...slim,
  cart: helpers.createApiReducer('cart'),
  contentful,
  discount_header: helpers.createApiReducer('discount_header'),
  error,
  filter,
  league_details: helpers.createApiReducer('league_details'),
  league_navigation: helpers.createApiReducer('league_navigation'),
  search,
  signup: helpers.createApiReducer('signup')
}
