import {actions} from '@teamrazr/redux'
const {types} = actions

export default (state = null, action) => {
  if (action.err) {
    console.error(action.err)
  }

  switch (action.type) {
    case types.RESET_ERROR:
      return null

    case types.SEARCH_ERROR:
      return true

    default:
      return state
  }
}
