import PropTypes from 'prop-types'
import React from 'react'
import {Helmet} from 'react-helmet'

export const titleTemplate = '%s'
export const defaultTitle =
  'Vintage Brand | Unique Sports Gifts, and Vintage College Memorabilia'

const HelmetWithTemplate = (props) => (
  <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
    {props.children}
  </Helmet>
)

HelmetWithTemplate.propTypes = {
  children: PropTypes.any
}

export default HelmetWithTemplate
