import blog from './blog'
import dynamicFeaturedProducts from './dynamic_featured_products'
import error from './error'
import filter from './filter'
import gift from './gift'
import session from './session'
import shipping from './shipping'
import {combineReducers} from 'redux'
import {extended as reducers} from '../../../redux/reducers'
import {helpers} from '@teamrazr/redux'
import {withReset} from '../../../redux/reducers/reset'

export default combineReducers({
  ...reducers,
  address: helpers.createApiReducer('address'),
  asset: helpers.createApiReducer('asset'),
  asset_variations: helpers.createApiReducer('asset_variations'),
  athlete: helpers.createApiReducer('athlete'),
  athlete_revision: helpers.createApiReducer('athlete_revision'),
  athlete_ref: helpers.createApiReducer('athlete_ref'),
  blog,
  cart_item: helpers.createApiReducer('cart_item'),
  cart_suggestions: helpers.createApiReducer('cart_suggestions'),
  college_athlete_dashboard: helpers.createApiReducer(
    'college_athlete_dashboard'
  ),
  country: helpers.createApiReducer('country'),
  discount: helpers.createApiReducer('discount'),
  dynamicFeaturedProducts,
  error,
  event: withReset(helpers.createApiReducer('event'), 'search'),
  event_group: helpers.createApiReducer('event_group'),
  event_group_ref: helpers.createApiReducer('event_group_ref'),
  event_type: helpers.createApiReducer('event_type'),
  featured_product: helpers.createApiReducer('featured_product'),
  filter,
  gift,
  hero: helpers.createApiReducer('hero'),
  league: helpers.createApiReducer('league'),
  league_ref: helpers.createApiReducer('league_ref'),
  league_ref_asset: helpers.createApiReducer('league_ref_asset'),
  league_ref_event: helpers.createApiReducer('league_ref_event'),
  league_ref_team: helpers.createApiReducer('league_ref_team'),
  league_ref_athlete: helpers.createApiReducer('league_ref_athlete'),
  order: helpers.createApiReducer('order'),
  order_item: helpers.createApiReducer('order_item'),
  order_status: helpers.createApiReducer('order_status'),
  partner: helpers.createApiReducer('partner'),
  preferences: helpers.createApiReducer('preferences'),
  product: helpers.createApiReducer('product'),
  product_group: helpers.createApiReducer('product_group'),
  product_rating: helpers.createApiReducer('product_rating'),
  product_type: helpers.createApiReducer('product_type'),
  product_type_rating: helpers.createApiReducer('product_type_rating'),
  session,
  shipping,
  state: helpers.createApiReducer('state'),
  tax_rate: helpers.createApiReducer('tax_rate'),
  team: helpers.createApiReducer('team'),
  team_ref: helpers.createApiReducer('team_ref'),
  team_revision: withReset(helpers.createApiReducer('team_revision'), 'search'),
  tile: helpers.createApiReducer('tile'),
  unrestricted_products: helpers.createApiReducer('unrestricted_products'),
  stripe_session: helpers.createApiReducer('stripe_session')
})
