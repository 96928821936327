import dotenv from 'dotenv'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import {helpers} from '@teamrazr/redux'

dotenv.config()

const url = process.browser
  ? '/api'
  : `http://localhost:${process.env.SERVER_PORT}/api`

export const createActions = helpers.createApi(url, {
  mapStateToOptions: ({cookie}) => {
    if (process.browser) {
      return {}
    }

    return {
      headers: {...omitBy({cookie}, isNil)}
    }
  }
})

export const throwIfErr = (arg) => {
  const {err} = arg || {}
  if (err) {
    console.error(err)
    throw err
  }

  return arg
}
