import first from 'lodash/first'
import sortBy from 'lodash/sortBy'

export const getRef = ({refs = []} = {}) => {
  const {ref} = first(sortBy(refs, 'sort')) || {}
  return ref
}

export const league = (l) => `/l/${getRef(l)}`

export const event = ({
  eventGroup,
  eventGroup: {
    eventType,
    eventType: {league}
  }
}) => `/l/${getRef(league)}/et/${getRef(eventType)}/e/${getRef(eventGroup)}`

export const eventType = (et) => `/l/${getRef(et.league)}/et/${getRef(et)}`

export const teamRevision = ({league, team}) =>
  `/l/${getRef(league)}/t/${getRef(team)}`

export const athlete = ({league, athlete}) =>
  `/l/${getRef(league)}/ath/${getRef(athlete)}`

export const athleteLandingPage = ({athlete}) =>
  `/site/athlete/${getRef(athlete)}`

export const asset = (a) => {
  if (!a) {
    return ''
  }

  if (
    a.labelEvent &&
    a.labelEvent.eventGroup &&
    a.labelEvent.eventGroup.eventType &&
    a.labelEvent.eventGroup.eventType.league
  ) {
    return event(a.labelEvent)
  }

  if (
    a.labelTeamRevision &&
    a.labelTeamRevision.league &&
    a.labelTeamRevision.team
  ) {
    return teamRevision(a.labelTeamRevision)
  }

  if (
    a.labelAthleteRevision &&
    a.labelAthleteRevision.league &&
    a.labelAthleteRevision.athlete
  ) {
    return athlete({
      athlete: a.labelAthleteRevision.athlete,
      league: a.labelAthleteRevision.league
    })
  }

  if (a.labelLeague) {
    return league(a.labelLeague)
  }

  return ''
}
