export function filterObject(obj, predicate = (a) => a != null) {
  const result = {}

  for (const key in obj) {
    const value = obj[key]
    if (predicate(value)) {
      result[key] = value
    }
  }

  return result
}
