import IndexRoute from 'react-router/lib/IndexRoute'
import Main from '../../components/main'
import PropTypes from 'prop-types'
import React from 'react'
import Route from 'react-router/lib/Route'
import {loadAsync, onRouteChange} from '../helpers'

const Routes = ({dispatch, getState}) => (
  <Route path="/" component={Main} onChange={onRouteChange(dispatch, getState)}>
    <IndexRoute
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "home_details" */ './components/home/details'
          )
      )}
    />

    <Route path="p">
      <IndexRoute
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "product_filter_list" */ './components/product_filter/list'
            )
        )}
      />
      <Route
        path="**"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "product_filter_list" */ './components/product_filter/list'
            )
        )}
      />
    </Route>

    <Route path="a">
      <Route path=":asset">
        <Route path="p">
          <Route path=":productGroup">
            <IndexRoute
              getComponent={loadAsync(
                () =>
                  import(
                    /* webpackChunkName: "asset_product_details" */ './components/asset/product/details'
                  )
              )}
            />
          </Route>
        </Route>
      </Route>
    </Route>

    <Route path="search">
      <IndexRoute
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "search_list" */ './components/search/list'
            )
        )}
      />
    </Route>

    <Route path="cart">
      <IndexRoute
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "cart_details" */ './components/cart/details'
            )
        )}
      />
    </Route>

    <Route path="order">
      <Route
        path="cancelled"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "order_cancelled" */ './components/order/cancelled'
            )
        )}
      />
      <Route
        path="status"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "order_status" */ './components/order/status'
            )
        )}
      />
      <Route path=":order">
        <Route
          path="confirmation"
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "order_confirmation" */ './components/order/confirmation'
              )
          )}
        />
        <Route
          path="status"
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "order_details" */ './components/order/details'
              )
          )}
        />
      </Route>
    </Route>

    <Route
      path="checkout"
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "checkout_header" */ './components/checkout/header'
          )
      )}
    >
      <IndexRoute
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "checkout_address" */ './components/checkout/address'
            )
        )}
      />
      <Route
        path="address"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "checkout_address" */ './components/checkout/address'
            )
        )}
      />
      <Route
        path="payment"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "checkout_payment" */ './components/checkout/payment'
            )
        )}
      />
      <Route
        path="review"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "checkout_review" */ './components/checkout/review'
            )
        )}
      />
    </Route>

    <Route
      path="stripe-checkout"
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "stripe_checkout_form" */ './components/checkout/stripe_checkout_form'
          )
      )}
    />

    <Route path="l">
      <Route path=":league/t">
        <IndexRoute
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "league_team_list" */ './components/league/team/list'
              )
          )}
        />
        <Route path=":team/a/:asset/p/:productGroup">
          <IndexRoute
            getComponent={loadAsync(
              () =>
                import(
                  /* webpackChunkName: "league_team_product" */ './components/league/team/product/details'
                )
            )}
          />
        </Route>
      </Route>

      <Route path=":league/dashboard">
        <IndexRoute
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "login" */ './components/league/athlete/dashboard/dashboard'
              )
          )}
        />
      </Route>

      <Route path=":league/ath">
        <IndexRoute
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "league_athlete_list" */ './components/league/athlete/list'
              )
          )}
        />
        <Route path=":athleteRef/a/:asset/p/:productGroup">
          <IndexRoute
            getComponent={loadAsync(
              () =>
                import(
                  /* webpackChunkName: "league_athlete_product" */ './components/league/athlete/product/details'
                )
            )}
          />
        </Route>
      </Route>

      <Route
        path=":league/et/:eventType/e/:event/a/:asset/p/:productGroup"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "league_event_product_details" */ './components/league/event_type/event_group/product/details'
            )
        )}
      />

      <Route
        path=":league/a/:asset/p/:productGroup"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "league_product_details" */ './components/league/product/details'
            )
        )}
      />

      <IndexRoute
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "product_filter_list" */ './components/product_filter/list'
            )
        )}
      />

      <Route
        path="**"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "product_filter_list" */ './components/product_filter/list'
            )
        )}
      />
    </Route>

    <Route path="site">
      <Route
        path="athlete/:athleteRef"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "promo" */ './contentful/athlete/athlete_page'
            )
        )}
      />
      <Route
        path="**"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "promo" */ './contentful/landing_page/index'
            )
        )}
      />
    </Route>

    <Route
      path="reviews"
      getComponent={loadAsync(
        () =>
          import(/* webpackChunkName: "reviews" */ './components/reviews/index')
      )}
    />

    <Route
      path="about"
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "about" */ './contentful/about/about_page'
          )
      )}
    />

    <Route
      path="gameday-tour"
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "gameday" */ './contentful/gameday_tour/gameday_tour_page'
          )
      )}
    />

    <Route
      path="contact"
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "static_contact" */ './contentful/contact/contact'
          )
      )}
    />

    <Route
      path="privacy"
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "static_privacy" */ './components/static/privacy'
          )
      )}
    />

    <Route
      path="terms"
      getComponent={loadAsync(
        () =>
          import(
            /* webpackChunkName: "static_terms" */ './components/static/terms'
          )
      )}
    />

    <Route
      path="cal"
      getComponent={loadAsync(
        () =>
          import(/* webpackChunkName: "static_cal" */ './components/static/cal')
      )}
    />

    <Route path="prefs">
      <IndexRoute
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "preferences" */ './components/preferences'
            )
        )}
      />
    </Route>

    <Route path="b">
      <IndexRoute
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "blog_list" */ './components/blog/list/index'
            )
        )}
      />

      <Route path="l/:leagueRef">
        <IndexRoute
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "blog_list" */ './components/blog/list/index'
              )
          )}
        />
        <Route
          path="**"
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "blog_details" */ './components/blog/details'
              )
          )}
        />
      </Route>

      <Route path="tag/:tagSlug">
        <IndexRoute
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "blog_list" */ './components/blog/list/index'
              )
          )}
        />
        <Route
          path="**"
          getComponent={loadAsync(
            () =>
              import(
                /* webpackChunkName: "blog_details" */ './components/blog/details'
              )
          )}
        />
      </Route>

      <Route
        path="p/**"
        getComponent={loadAsync(
          () =>
            import(
              /* webpackChunkName: "blog_details" */ './components/blog/details'
            )
        )}
      />
    </Route>

    <Route
      path="404"
      getComponent={loadAsync(
        () =>
          import(/* webpackChunkName: "static_404" */ './components/static/404')
      )}
    />

    <Route
      path="500"
      getComponent={loadAsync(
        () =>
          import(/* webpackChunkName: "static_500" */ './components/static/500')
      )}
    />

    <Route
      path="**"
      getComponent={loadAsync(
        () =>
          import(/* webpackChunkName: "static_404" */ './components/static/404')
      )}
    />
  </Route>
)

Routes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired
}

export default Routes
