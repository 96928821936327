import browserHistory from 'react-router/lib/browserHistory'
import {helpers} from '@teamrazr/redux'

export const setFilterMounted = helpers.createAction(
  'FILTER_MOUNTED',
  'mounted'
)

const _setFilterMenuShow = helpers.createAction('FILTER_MENU_SHOW', 'show')
export const setFilterMenuShow = (show) => (dispatch, getState) => {
  if (!getState().filter.global.mounted) {
    browserHistory.push('/p')
  }

  return dispatch(_setFilterMenuShow(show))
}
