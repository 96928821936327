import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import PropTypes from 'prop-types'
import React, {useCallback, useState} from 'react'
import {css} from 'glamor'

const style = {
  container: css({
    backgroundColor: '#333',
    color: '#fff',
    padding: '18px 0',
    '& a': {
      color: '#fff'
    },
    '& h4': {
      marginBottom: 0
    }
  }),
  button: css({
    color: '#fff !important',
    fontSize: '26px',
    lineHeight: 0,
    padding: 0,
    textDecoration: 'none !important'
  })
}

const Banner = ({canDismiss, children, ...props}) => {
  const [dismissed, setDismissed] = useState(false)
  const onDismiss = useCallback(() => {
    setDismissed(true)
  }, [setDismissed])

  if (dismissed) {
    return null
  }

  return (
    <div {...style.container} {...props}>
      <Container>
        {children}
        {canDismiss && (
          <span className="float-end">
            <Button variant="link" onClick={onDismiss} {...style.button}>
              &times;
            </Button>
          </span>
        )}
      </Container>
    </div>
  )
}

Banner.propTypes = {
  canDismiss: PropTypes.bool,
  children: PropTypes.any
}

Banner.defaultProps = {
  canDismiss: true
}

export default Banner
