import {createActions} from '../index'

export const leagueNavigation = createActions(
  'league',
  ['league_navigation'],
  'league_navigation'
)

export const leagueDetails = createActions(
  'league',
  ['league_details'],
  'league_details'
)

export const SELECT = '*,refs:league_ref(*)'

export const SELECT_DETAILS = `
  ${SELECT},
  event_types:event_type(
    *,
    refs:event_type_ref(*),
    groups:event_group(*,refs:event_group_ref(*))
  ),
  team_revisions:team_revision(*,team(*,refs:team_ref(*))),
  athlete_revisions:athlete_revision(*,athlete(*,refs:athlete_ref(*))),
  league_asset(count)
`.replace(/\s/g, '')

export const fetchLeagues = (query, cached) =>
  leagueNavigation.get({query: {...query, select: SELECT}, cached})

export const fetchLeaguesDetails = (query, cached) =>
  leagueDetails.get({query: {...query, select: SELECT_DETAILS}, cached})
