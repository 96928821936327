import {actions} from '@teamrazr/redux'
import {withReset} from './reset'

const {types} = actions

export default withReset((state = {items: []}, action) => {
  switch (action.type) {
    case types.SEARCH:
      return {
        ...state,
        query: action.query,
        source: action.source,
        loading: true
      }

    case types.SEARCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        query: action.query,
        loading: false
      }

    case types.SEARCH_ERROR:
      return {...state, loading: false}

    default:
      return state
  }
}, 'search')
