import {actions, helpers} from '@teamrazr/redux'
const {types} = actions

const reducer = helpers.createApiReducer('gift')

export default function (state, action) {
  switch (action.type) {
    case types.REMOVE_GIFT:
      return {...state, removed: {...state.removed, [action.id]: true}}

    default:
      return reducer(state, action)
  }
}
