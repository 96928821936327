import {actions, reducers} from '@teamrazr/redux'
const {types} = actions

export default function (state, action) {
  switch (action.type) {
    case types.UPDATE_SESSION_SUCCESS:
      return {...state, ...action.body}

    default:
      return reducers.identity(state, action)
  }
}
