import {css} from 'glamor'
import {slice, sortBy} from 'lodash'
import PropTypes from 'prop-types'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavItem from 'react-bootstrap/NavItem'
import Navbar from 'react-bootstrap/Navbar'
import {useDispatch, useSelector} from 'react-redux'
import browserHistory from 'react-router/lib/browserHistory'
import {fetchCartWithProductsAndShippingFromApi} from '../bundle/client/actions/cart'
import {fetchProducts} from '../bundle/client/actions/product'
import {getBanner} from '../redux/actions/contentful'
import {fetchDiscountHeaders} from '../redux/actions/discount'
import {setFilterMenuShow} from '../redux/actions/filter'
import {fetchLeagues, fetchLeaguesDetails} from '../redux/actions/league'
import Banner from './banner'
import {useWindowSize} from './hooks/use_window_size'
import Icon, {icons} from './icon'
import {Menu, Overlay} from './mega_menu'
import Search from './search'

const style = {
  container: css({
    position: 'relative',
    '@media (max-width: 767px)': {
      borderBottom: '1px solid #eee'
    }
  }),
  navbar: css({
    marginBottom: 0,
    zIndex: 50,
    '@media (min-width: 768px)': {
      borderColor: '#eee',
      height: '80px'
    }
  }),
  navbarBrand: css({padding: 0}),
  nav: css({
    alignItems: 'center',
    gap: '15px',
    '& > li': {
      display: 'inline-block',
      '& > a': {
        paddingTop: '28px',
        '@media (max-width: 767px)': {
          paddingTop: '22px'
        }
      },
      '& a svg': {
        fontSize: '1.3em'
      }
    }
  }),
  search: css({
    float: 'right',
    padding: '0 15px'
  }),
  cart: css({
    position: 'relative',
    '& > small': {
      borderRadius: '50%',
      width: '15px',
      height: '15px',
      color: '#fff',
      background: '#19a449',
      position: 'absolute',
      right: '8px',
      top: '50%',
      marginTop: '-18px',
      textAlign: 'center',
      lineHeight: '17px',
      fontSize: '11px',
      '@media(max-width: 767px)': {
        marginTop: '-9px'
      }
    }
  }),
  banner: css({
    '& p': {
      margin: '3px 0',
      '@media(min-width: 768px)': {
        fontSize: '14px'
      }
    }
  }),
  siteBanner: css({
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    '& h4': {
      lineHeight: '1.2'
    },
    '& a': {
      color: '#fff'
    },
    '& a:hover': {
      color: '#D3D3D3'
    }
  }),
  bannerText: css({
    textDecoration: 'underline',
    textUnderlineOffset: '5px',
    fontFamily: 'Rubik',
    fontWeight: '100',
    letterSpacing: '1px'
  })
}

const SiteBanner = ({children, style: customStyle}) => (
  <section {...css(style.banner, style.siteBanner, customStyle)}>
    {children}
  </section>
)

SiteBanner.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
}

const HeaderNav = (props) => {
  const dispatch = useDispatch()

  /** @type {App.Vintage.Synthetic.Cart} */
  const cart = useSelector((state) => state.cart.items[0])

  /** @type {App.Vintage.Base.Discount} */
  const discount = useSelector((state) => state.discount_header.items[0])
  const contentful = useSelector((state) => state.contentful)
  const leagues = useSelector((state) => state.league_navigation.items)

  const {screenSize} = useWindowSize()

  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState({selected: false, search: false})

  // Note: synchronize selected state with `props.location` so
  // routing/navigation clears selections and search state
  useEffect(() => {
    setSelected(null)
    setShow({selected: false, search: false})
  }, [props.location, setSelected, setShow])

  // Use a ref to store the result of `setTimeout` so we can clean things up
  const timeout = useRef(null)

  const onHide = useCallback(() => {
    clearTimeout(timeout.current)
    timeout.current = null
    setSelected(null)
    setShow((show) => ({...show, selected: false}))
  }, [setSelected, setShow])

  const onSelect = useCallback(
    (selected) => {
      setSelected(selected)

      if (!timeout.current) {
        dispatch(fetchLeaguesDetails(null, true))
        timeout.current = setTimeout(
          () => setShow((show) => ({...show, selected: true})),
          150
        )
      }
    },
    [setSelected, dispatch, setShow]
  )

  const onNavItemClick = useCallback((e) => {
    e.preventDefault()
    browserHistory.push(e.currentTarget.attributes.href.value)
  }, [])

  const onShowSearch = useCallback(
    () => setShow((show) => ({...show, search: true})),
    [setShow]
  )

  const onHideSearch = useCallback(
    () => setShow((show) => ({...show, search: false})),
    [setShow]
  )

  const onShowFilterMenu = useCallback(() => {
    dispatch(setFilterMenuShow(true))
  }, [dispatch])

  const {headerLeagues, secondaryLeagues} = useMemo(() => {
    const leaguesWithSortOrder = sortBy(
      leagues.filter((l) => !!l.sort),
      'sort'
    )

    const leaguesWithoutSortOrder = leagues.filter(
      (l) => l.sort == null && l.refs.length && l.type
    )

    // limit header leagues to 4 at the medium screen size, 5 otherwise. The additional leagues will be moved to the "More" dropdown.
    const splitAt = screenSize === 'md' ? 4 : 5
    return {
      headerLeagues: slice(leaguesWithSortOrder, 0, splitAt),
      secondaryLeagues: [
        ...slice(leaguesWithSortOrder, splitAt),
        ...leaguesWithoutSortOrder
      ]
    }
  }, [leagues, screenSize])

  const {items, banners} = contentful
  const findByDate = (ids, date) =>
    ids
      .map((id) => items[id])
      .find(
        (item) =>
          date > new Date(item.fields.startDate).getTime() &&
          date < new Date(item.fields.endDate).getTime()
      )

  const date = Date.now()
  const banner = findByDate(banners, date)

  const UrlBanner = (props) => (
    <Fragment>
      {props.banner && (
        <SiteBanner style={{background: props.banner.fields.background}}>
          <a href={props.banner.fields.url} {...style.bannerText}>
            {props.banner.fields.text}
          </a>
        </SiteBanner>
      )}
    </Fragment>
  )

  const NormalBanner = (props) => (
    <Fragment>
      <SiteBanner style={{background: props.banner.fields.background}}>
        <p {...style.bannerText}>{props.banner.fields.text}</p>
      </SiteBanner>
    </Fragment>
  )

  const RenderBanner = (props) => {
    if (props.banner.fields.url) {
      return <UrlBanner banner={props.banner} />
    } else {
      return <NormalBanner banner={props.banner} />
    }
  }

  return (
    <Fragment>
      {banner && <RenderBanner banner={banner} />}
      <div {...style.container} onMouseLeave={onHide}>
        <Navbar {...style.navbar}>
          <Container>
            <Navbar.Brand {...style.navbarBrand}>{props.title}</Navbar.Brand>
            <Menu
              onHide={onHide}
              onSelect={onSelect}
              selected={selected}
              headerLeagues={headerLeagues}
            />

            <Nav {...style.nav}>
              <div {...style.search} className="d-none d-md-block">
                <Search onHide={onHideSearch} show={show.search} {...props} />
              </div>
              <NavItem
                className="d-inline-block d-md-none"
                onClick={onShowSearch}
              >
                <Icon icon={icons.search} />
              </NavItem>
              <NavItem
                href="/cart"
                onClick={onNavItemClick}
                {...style.cart}
                data-testid="header-cart-link"
              >
                <Icon icon={icons.shoppingCart} />
                {cart && cart.items && !!cart.items.length && (
                  <small data-testid="cart-item-count">
                    {cart.items.length}
                  </small>
                )}
              </NavItem>
              <NavItem
                className="d-inline-block d-lg-none"
                onClick={onShowFilterMenu}
              >
                <Icon icon={icons.bars} />
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
        <Overlay
          onHide={onHide}
          selected={show.selected ? selected : null}
          secondaryLeagues={secondaryLeagues}
        />
      </div>
      {discount && (
        <Banner className="text-center" canDismiss={false} {...style.banner}>
          <h4>{discount.header}</h4>
        </Banner>
      )}
    </Fragment>
  )
}

HeaderNav.initialize = ({dispatch}) => {
  dispatch(fetchCartWithProductsAndShippingFromApi(true))
  return Promise.all([
    dispatch(fetchDiscountHeaders(true)),
    dispatch(fetchProducts(true)),
    dispatch(getBanner()),
    dispatch(fetchLeagues({order: 'sort.asc'}, true))
  ])
}

HeaderNav.propTypes = {
  location: PropTypes.object,
  title: PropTypes.node
}

export default HeaderNav
