export const checkIsProdWithFake = (source) =>
  checkIsProdWithoutFake(source) ||
  source?.env?.FAKE_ENV === 'prod' ||
  source?.env?.FAKE_ENV === 'production' ||
  source?.FAKE_ENV === 'prod' ||
  source?.FAKE_ENV === 'production'

export const checkIsProdWithoutFake = (source) =>
  checkAllEnv(source, 'prod') || checkAllEnv(source, 'production')

const checkAllEnv = (source, value) =>
  [
    source,
    source?.ENV,
    source?.NODE_ENV,
    source?.environment,
    source?.env,
    source?.env?.ENV,
    source?.env?.NODE_ENV,
    source?.env?.environment
  ].includes(value)

export const checkIsLocalUrl = (source) =>
  /local/i.test(source) ||
  /local/i.test(source?.SERVER_URLS) ||
  /local/i.test(source?.env?.SERVER_URLS)

export const checkIsStagingUrl = (source) =>
  /staging/i.test(source) ||
  /staging/i.test(source?.SERVER_URLS) ||
  /staging/i.test(source?.env?.SERVER_URLS)
