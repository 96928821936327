import reducers from './reducers'
import {rehydrateCss, renderApp} from '../init'

// Common imports we don't want repeated in indvidual route bundles
import 'react-transition-group'
import '../../shared/asset'
import '../../shared/utils'

rehydrateCss()
const routes = require('./routes').default
renderApp(reducers, routes)
