import get from 'lodash/get'
import find from 'lodash/find'
import {actions} from '@teamrazr/redux'
const {types} = actions

const initialState = {
  items: {},
  banners: [],
  loading: false,
  urls: {}
}

const findById = (items, id) => find(items, ['sys.id', id])

const parseField = (items, subfield) => {
  const entry = findById(
    items[subfield.sys.linkType || subfield.sys.type],
    subfield.sys.id
  )

  return parseEntry(items, entry)
}

const parseEntry = (items, entry) => {
  for (let field in entry ? entry.fields : []) {
    if (
      Array.isArray(entry.fields[field]) &&
      entry.fields[field].length &&
      entry.fields[field][0].sys
    ) {
      entry.fields[field] = entry.fields[field].map((subfield) =>
        parseField(items, subfield)
      )
    }

    if (entry.fields[field].sys) {
      entry.fields[field] = parseField(items, entry.fields[field])
    }
  }

  return entry
}

export default function (state = initialState, action) {
  const loading = false

  const {body} = action

  switch (action.type) {
    case types.GET_CONTENTFUL:
      return {
        ...state,
        loading: true
      }

    case types.GET_CONTENTFUL_SUCCESS: {
      const items = {...state.items}
      body.items.forEach((entry) => {
        items[entry.sys.id] = parseEntry(body.includes, entry)
      })

      const urls = {...state.urls}
      if (get(action.query, 'fields.metaData.fields.canonicalUrl[match]')) {
        urls[action.query['fields.metaData.fields.canonicalUrl[match]']] =
          body.items[0].sys.id
      }

      if (get(action.query, 'fields.url[in]')) {
        body.items.forEach((item) => {
          item.fields.url.forEach((url) => {
            urls[url] = item.sys.id
          })
        })
      }

      let banners = [...state.banners]
      if (action.query.content_type === 'banner') {
        banners.push(...body.items.map((item) => item.sys.id))
      }

      return {
        ...state,
        items,
        banners,
        loading,
        urls
      }
    }

    case types.GET_CONTENTFUL_ERROR:
      return {...state, loading}

    default:
      return state
  }
}
