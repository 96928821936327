const assetVariations = {
  select: 'variations:asset_variations(*)',
  extended: 'variations:asset_variations(*,asset_variation(*))'
}

const base = `
  id,
  description,
  location,
  metadata,
  colors:asset_color(additive,color_id),
  height:full_height,
  width:full_width,
  year,
  labelLeague:league!asset_label_league_id_fkey(
    id,abbreviation,label,
    refs:league_ref(ref,sort)
  ),
  labelHistoricalAthlete:historical_athlete!asset_label_athlete_id_fkey(id,first_name,last_name),
  labelEvent:event!asset_label_event_id_fkey(
    id,year,eventGroup:event_group(
      id,label,
      refs:event_group_ref(ref,sort),
      eventType:event_type(
        id,league_id,
        refs:event_type_ref(ref,sort),
        league(id,refs:league_ref(ref,sort))
      )
    )
  ),
  labelTeamRevision:team_revision!asset_label_team_revision_id_fkey(
    id,name,league_id,
    team(id,display,label,refs:team_ref(ref,sort),colors:team_color(color_id)),
    league(id,refs:league_ref(ref,sort))
  ),
  labelAthleteRevision:athlete_revision!asset_label_athlete_revision_id_fkey(
    id,league_id,
    athlete(id,first_name,last_name,refs:athlete_ref(ref,sort)),
    league(id,refs:league_ref(ref,sort))
  )
`.replace(/\s/g, '')

export const select = `${base},${assetVariations.select}`

export const extended = `
  ${base},
  ${assetVariations.extended},
  events:event_asset(event(id,label,event_group(event_type(league(*))))),
  team_revisions:team_revision_asset(
    team_revision!team_revision_asset_team_revision_id_fkey(team(id,label,display),league(*))
  ),
  athlete_revisions:athlete_revision_asset(
    athlete_revision(athlete(id,first_name,last_name),league(*))
  ),
  leagues:league_asset(league(*))
`.replace(/\s/g, '')
