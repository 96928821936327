import {actions} from '@teamrazr/redux'

const {types} = actions

export const withReset = (reducer, key) => (state, action) =>
  reducer(
    action.type === types.RESET && (key == null || action.key === key)
      ? undefined
      : state,
    action
  )
