import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

/**
 * @template {App.Vintage.Synthetic.CartItem | App.Vintage.Synthetic.OrderItem} T
 * @param {T[]} items
 * @return {{grouped: Record<string, (T & { key: string })[]>, sorted: (T & { key: string })[]}}
 */
export const sortAndGroup = (items) => {
  /** @type {(T & { key: string })[]} */
  const keyed = items.map((item) => ({...item, key: itemKey(item)}))
  return {
    grouped: groupBy(keyed, 'key'),
    sorted: sortBy(uniqBy(keyed, 'key'), 'key')
  }
}

/**
 * @param {App.Vintage.Synthetic.CartItem | App.Vintage.Synthetic.OrderItem} item
 * @returns {string}
 */
export const itemKey = (item) =>
  [item.sku.id, item.asset_variation.id, item.asset_variations.id].join('-')
