export const pushGTMData = (data) => {
  // console.log('pushGTMData', 'data', data)
  return new Promise((resolve) => {
    const options = {...data}

    if (options?.event) {
      const callBack = (...args) => {
        try {
          data?.eventCallback && data?.eventCallback(...args)
          // console.debug('GTM Event Callback', args)
        } catch (err) {
          console.warn('GTM Event Callback Error', err)
        }
        resolve(options)
      }

      if (window?.dataLayer) {
        options.eventCallback = callBack
      } else {
        if (!window?.vintageDataLayerEventCallbacks) {
          window.vintageDataLayerEventCallbacks = {}
        }
        const rand = Math.round(Math.random() * 1000000)
        window.vintageDataLayerEventCallbacks[rand] = callBack
        // Piggyback the random number for the callback onto the eventCallback property to be serialized in pushOrFallback
        options.eventCallback = rand
      }
      pushOrFallback(options)
    } else {
      pushOrFallback(options)
      resolve(options)
    }
  })
}

const pushOrFallback = (options) => {
  try {
    if (window?.dataLayer?.push) {
      try {
        // console.log(
        //   'pushGTMData',
        //   'window?.dataLayer?.push',
        //   'options',
        //   options
        // )
        window?.dataLayer?.push(options)
        // console.debug('GTM Datalayer Bundle Push', options)
      } catch (err) {
        console.warn('GTM Datalayer Push Error', err)
        options?.eventCallback && options.eventCallback()
      }
    } else {
      /*
       * If window.dataLayer doesn't exist for some reason, create a script tag to execute it in another context.
       * This was being done elsewhere by Zach and I don't know why, so I wanted to keep that option in case it was intentional.
       * Our hypothesis is that it was for SRR, but I'm not sure how to test that.
       * If there is a key like "eventCallback": 1234, then replace it with a function to call a
       * globally scoped callback function that was defined in pushGTMData.
       */
      const optionsString = JSON.stringify(options).replace(
        /"eventCallback":\s*(\d+)/,
        '"eventCallback":(...args)=>vintageDataLayerEventCallbacks[$1](...args)'
      )
      const js = document?.createElement('script')
      js.innerHTML = `
      ((options) => {
        try {
          if (dataLayer?.push) {
            dataLayer?.push(options);
            // console.debug('GTM Datalayer Script Tag Push', options)
          } else {
            options?.eventCallback && options.eventCallback()
          }
        } catch (err) {
          options?.eventCallback && options.eventCallback()
        }
      })(${optionsString})`
      const body =
        document?.body ||
        document?.querySelector('body') ||
        document?.getElementsByTagName('body')[0]
      body?.appendChild(js)
    }
  } catch (err) {
    console.warn('GTM Error', err)
  }
}
