import error from '../../../redux/reducers/error'
import {actions} from '@teamrazr/redux'

const {types} = actions

export default function (state, action) {
  switch (action.type) {
    case types.ORDER_ERROR:
      return {...action.err, order: true}

    case 'CREATE_ADDRESS_ERROR':
    case 'UPDATE_ADDRESS_ERROR':
      return {...action, address: true, message: action.err.message}

    default:
      return error(state, action)
  }
}
