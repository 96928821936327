import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Row from 'react-bootstrap/Row'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error) {
    this.setState({error})
    try {
      console.error(error)
    } catch (err) {
      // What can we do, you know?
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({error: null})
  }

  render() {
    const {children} = this.props
    const {error} = this.state

    if (error) {
      return (
        <Container>
          <Row>
            <Col xs={12}>
              <h1>OOPS</h1>
              <p>Something just broke on our end. Sorry about that!</p>
            </Col>
          </Row>
        </Container>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element
}

export default ErrorBoundary
