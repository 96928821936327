export const generateRemoveFromCartGALegacyGTMData = ({items}) => ({
  event: 'removeFromCart',
  ecommerce: {
    currencyCode: 'USD',
    remove: {
      products: items.map(({product, sku, asset_variations: variations}) => ({
        category: product.product_group.label,
        id: sku.id,
        price: product.price,
        quantity: 1,
        variant: variations.id
      }))
    }
  }
})
