import compact from 'lodash/compact'
import {combineReducers} from 'redux'
import {helpers} from '@teamrazr/redux'

const filter = helpers.createApiReducer('filter', 'dynamic_featured_filter')
const initialFilterState = {
  ...filter(undefined, {type: undefined}),
  data: {}
}

export default combineReducers({
  productType: helpers.createApiReducer(
    'productType',
    'dynamic_featured_product_type'
  ),
  filter: (state = initialFilterState, action) => {
    switch (action.type) {
      case 'GET_DYNAMIC_FEATURED_FILTER_SUCCESS': {
        const result = filter(state, action)
        const {
          query: {pt, ps},
          body: {page = []}
        } = action
        const key = compact([pt, ps]).join(',')

        return {
          ...result,
          data: {...state.data, [key]: page}
        }
      }

      default:
        return filter(state, action)
    }
  }
})
