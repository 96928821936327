export const scrollToTop = () => {
  window.scrollTo(0, 0)
  /**
   * Safari doesn't want to scroll to the top on page navigation.
   * I think it has something to do with lazy loading or page load times.
   * It doesn't seem to affect Chrome.
   * This is a quick fix, but if it keeps happening, then I will need to investigate it further.
   */
  setTimeout(() => window.scrollTo(0, 0), 0)
  setTimeout(() => window.scrollTo(0, 0), 100)
}

export const loadAsync =
  (promise, key = 'default') =>
  async () => {
    const {[key]: component} = await promise()
    return component
  }

export const onRouteChange = (dispatch, getState) => {
  // A promise used below to keep track of component initialization
  let componentsInit

  return async (prevState, nextState, replace, callback) => {
    // If we're doing a replace, wait for any pending initialization to
    // complete before allowing a route change.
    if (nextState.location.action === 'REPLACE') {
      return componentsInit ? componentsInit.then(callback) : callback()
    }

    const componentsToInitialize = (
      await Promise.all(
        nextState.routes.map((route) =>
          route.getComponent
            ? route.getComponent()
            : Promise.resolve(route.component)
        )
      )
    ).filter(
      (component) => component && typeof component.initialize === 'function'
    )

    if (!componentsToInitialize.length) {
      scrollToTop()
      return callback()
    }

    componentsInit = Promise.all(
      componentsToInitialize.map((component) =>
        component.initialize({dispatch, getState, ...nextState})
      )
    ).then(() => {
      componentsInit = null
      callback()
      scrollToTop()
    })

    return componentsInit
  }
}

export const handleWindowResize = () => {
  const resolution = window.innerWidth
  return resolution >= 320 && resolution <= 480
}

export const eqQuery = (obj) => {
  const query = {}
  Object.entries(obj).forEach(([key, value]) => (query[key] = `eq.${value}`))

  return query
}
