export const generateAddToCartGALegacyGTMData = ({
  productCategory,
  productId,
  productPrice,
  productQuantity,
  productVariant
}) => ({
  event: 'addToCart',
  ecommerce: {
    currencyCode: 'USD',
    add: {
      products: [
        {
          category: productCategory,
          id: productId,
          price: productPrice,
          quantity: productQuantity,
          variant: productVariant
        }
      ]
    }
  }
})
