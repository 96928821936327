import PropTypes from 'prop-types'
import React from 'react'
import Router from 'react-router/lib/Router'
import {Provider} from 'react-redux'
import {Auth0Provider} from '@auth0/auth0-react'

const Root = (props) => (
  <Auth0Provider
    domain="vintagebrand.us.auth0.com"
    clientId="oQ1tmaTUPCfXQ7l5QhdKhMOTeCQPCEc7"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/l/college-athletes/dashboard`
    }}
    useRefreshTokens
    cacheLocation="localstorage"
  >
    <Provider store={props.store}>
      <Router {...props} />
    </Provider>
  </Auth0Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
