// @ts-check

import {css} from 'glamor'
import {uniqBy} from 'lodash'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import React, {Fragment, useCallback, useEffect, useState} from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {useSelector} from 'react-redux'
import Link from 'react-router/lib/Link'
import * as labels from '../shared/labels'
import * as prefixes from '../shared/prefixes'
import Icon, {icons} from './icon'
import Button from './link_button'

const style = {
  menu: css({
    float: 'left',
    padding: '22px 0 0',
    '& > .list-inline li': {
      padding: '0 16px'
    },
    '& h4': {
      fontSize: '16px',
      fontWeight: 'normal',
      textTransform: 'uppercase'
    },
    '& h5': {
      fontSize: '16px'
    },
    '& a': {
      display: 'inline-block',
      textDecoration: 'none',
      '&.active': {
        borderBottom: '2px solid #000'
      }
    }
  }),
  overlay: css({
    backgroundColor: 'white',
    boxShadow: '5px 5px 5px -5px #dadada',
    paddingBottom: '2rem',
    paddingTop: '5rem',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 49,
    '& > div': {
      paddingTop: '30px',
      borderTop: '1px solid #dadada'
    },
    '& a': {
      textDecoration: 'none',
      '& h5': {
        fontSize: '16px',
        fontWeight: 'normal',
        textTransform: 'uppercase',
        '& i': {
          color: '#dadada'
        }
      },
      '&:hover': {
        textDecoration: 'underline'
      },
      '&.active': {
        '& h5': {
          fontWeight: 'bold'
        },
        '& i': {
          color: '#333'
        }
      }
    }
  }),
  fullHeight: css({height: '100%', minHeight: '150px'}),
  left: css({
    paddingRight: '30px',
    '& li': {
      margin: '20px 0',
      '&:first-child': {
        marginTop: 0
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    '& a': {
      display: 'block',
      textDecoration: 'none',
      '&:active, &:focus, &:hover, &:visited': {
        textDecoration: 'none'
      }
    }
  }),
  right: css({
    borderLeft: '1px solid #dadada',
    height: '100%',
    minHeight: '150px',
    paddingLeft: '30px',
    '& li': {
      margin: '10px 0',
      '&:first-child': {
        marginTop: 0
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  }),
  more: css({
    '& li': {
      margin: '10px 0',
      '&:first-child': {
        marginTop: 0
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  }),
  viewAll: css({
    marginTop: '20px',
    minWidth: '200px'
  }),
  leftMenuPicker: css({display: 'flex', justifyContent: 'space-between'})
}

const group = (items, max = 10, count = 4) => {
  count = Math.min(items.length, count)
  items = sortBy(
    items.filter(({sort}) => sort != null),
    'sort'
  ).slice(0, max * count)

  const groups = []
  for (let i = 0; i < count; i++) {
    const offset = i * max
    groups[i] = items.slice(offset, offset + max)
  }

  return groups
}

const More = ({secondaryLeagues, onHide}) => (
  <ul className="list-unstyled">
    {secondaryLeagues.map((l) => (
      <li key={l.id}>
        <Link onClick={onHide} to={prefixes.league(l)}>
          {labels.league(l)}
        </Link>
      </li>
    ))}
  </ul>
)

More.propTypes = {
  secondaryLeagues: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired
}

const EventType = ({eventType, league, onHide}) => {
  const groups = group(eventType.groups || [])

  return (
    <Fragment>
      <Row>
        {groups.map((group, i) => (
          <Col key={i} xs={12 / groups.length}>
            <ol className="list-unstyled">
              {sortBy(group, 'sort')
                .filter(({refs}) => refs.length)
                .map((eventGroup) => (
                  <li key={eventGroup.id}>
                    <Link
                      onClick={onHide}
                      to={prefixes.event({
                        eventGroup: {
                          ...eventGroup,
                          eventType: {...eventType, league}
                        }
                      })}
                    >
                      {eventGroup.label}
                    </Link>
                  </li>
                ))}
            </ol>
          </Col>
        ))}
      </Row>
      {eventType.view_all && (
        <div className="text-center">
          <Button
            variant="outline-dark"
            size="lg"
            onClick={() => onHide()}
            to={prefixes.eventType({...eventType, league})}
            {...style.viewAll}
          >
            View All
          </Button>
        </div>
      )}
    </Fragment>
  )
}

EventType.propTypes = {
  eventType: PropTypes.object.isRequired,
  league: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired
}

/**
 * @typedef {Object} TeamProps
 * @property {Object} league
 * @property {function} onHide
 */

/** @type {React.FC<TeamProps>} */
const Teams = ({league, onHide}) => {
  const activeTeamRevisions = league.team_revisions.filter((tr) => tr.active)

  const teams = sortBy(
    uniqBy(
      activeTeamRevisions.map((tr) => tr.team),
      'id'
    ),
    labels.team
  )
  const groups = group(teams)

  return (
    <Fragment>
      <Row>
        {groups.map((group, i) => (
          <Col key={i} xs={12 / groups.length}>
            <ol className="list-unstyled">
              {sortBy(group, 'sort')
                .filter(({refs}) => refs.length)
                .map((team) => (
                  <li key={team.id}>
                    <Link
                      to={prefixes.teamRevision({league, team})}
                      onClick={() => onHide()}
                    >
                      {labels.team(team)}
                    </Link>
                  </li>
                ))}
            </ol>
          </Col>
        ))}
      </Row>
      <div className="text-center">
        <Button
          size="lg"
          variant="outline-dark"
          onClick={() => onHide()}
          to={`${prefixes.league(league)}/t`}
          {...style.viewAll}
        >
          View All
        </Button>
      </div>
    </Fragment>
  )
}

Teams.propTypes = {
  league: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired
}

/**
 * @typedef {Object} Props
 * @property {Object} league
 * @property {function} onHide
 */

/** @type {React.FC<Props>} */
const Athletes = ({league, onHide}) => {
  const activeAthleteRevisions = (league.athlete_revisions || []).filter(
    (ar) => ar.active
  )

  const sortedAthletes = sortBy(
    uniqBy(
      activeAthleteRevisions.map((ar) => ar.athlete),
      'id'
    ),
    labels.athlete
  )

  const sortedAthletesWithRefs = sortedAthletes.filter(({refs}) => refs.length)
  const groups = group(sortedAthletesWithRefs)
  return (
    <Fragment>
      <Row>
        {groups.map((group, i) => (
          <Col key={i} xs={12 / groups.length}>
            <ol className="list-unstyled">
              {sortBy(group, 'sort').map((athlete) => (
                <li key={athlete.id}>
                  <Link
                    to={prefixes.athleteLandingPage({athlete})}
                    onClick={() => onHide()}
                  >
                    {labels.athlete(athlete)}
                  </Link>
                </li>
              ))}
            </ol>
          </Col>
        ))}
      </Row>
      <div className="text-center">
        <Button
          size="lg"
          variant="outline-dark"
          onClick={() => onHide()}
          to={`${prefixes.league(league)}/ath`}
          {...style.viewAll}
        >
          View All
        </Button>
      </div>
    </Fragment>
  )
}

Athletes.propTypes = {
  league: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired
}

export const Overlay = ({onHide, selected, secondaryLeagues}) => {
  const leagues = useSelector((state) => state.league_details.items)

  const [option, setOption] = useState('leagues')

  useEffect(() => {
    setOption(null)
  }, [selected, setOption])

  const onLeagueOption = useCallback((e) => {
    e && e.preventDefault()
    setOption('leagues')
  }, [])

  if (!(selected && leagues.length)) {
    return null
  }

  const league = leagues.find((league) => league.id === selected)

  if (selected === 'more') {
    return (
      <div className="d-none d-lg-block" {...style.overlay}>
        <div>
          <Container {...style.fullHeight}>
            <Row {...style.fullHeight}>
              <Col xs={12} {...style.more}>
                <More secondaryLeagues={secondaryLeagues} onHide={onHide} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  const eventType =
    option !== 'leagues'
      ? league.event_types.find((et) => et.id === option)
      : null

  return (
    <div className="d-none d-lg-block" {...style.overlay}>
      <div>
        <Container {...style.fullHeight}>
          <Row {...style.fullHeight}>
            <Col xs={3} {...style.left}>
              <ol className="list-unstyled">
                <li>
                  <a
                    className={option === 'leagues' ? 'active' : null}
                    href="#"
                    onClick={onLeagueOption}
                    onMouseEnter={onLeagueOption}
                  >
                    <h5 {...style.leftMenuPicker}>
                      {league.type === 'athlete' ? 'Athletes' : 'Teams'}
                      <Icon icon={icons.chevronRight} />
                    </h5>
                  </a>
                </li>

                {league.event_types.map((et) => (
                  <li key={et.id}>
                    <a
                      className={option === et.id ? 'active' : null}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        setOption(et.id)
                      }}
                      onMouseEnter={() => setOption(et.id)}
                    >
                      <h5 {...style.leftMenuPicker}>
                        {et.label} <Icon icon={icons.chevronRight} />
                      </h5>
                    </a>
                  </li>
                ))}
              </ol>
            </Col>
            <Col xs={9} {...style.right}>
              {eventType ? (
                <EventType
                  eventType={eventType}
                  league={league}
                  onHide={onHide}
                />
              ) : league.type === 'team' ? (
                <Teams league={league} onHide={onHide} />
              ) : (
                <Athletes league={league} onHide={onHide} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

Overlay.propTypes = {
  onHide: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondaryLeagues: PropTypes.array
}

export const Menu = ({onHide, onSelect, selected, headerLeagues}) => {
  return (
    <div {...style.menu} className="d-none d-lg-block">
      <ol className="list-unstyled list-inline d-flex">
        {headerLeagues.map((l) => {
          const active = selected === l.id

          return (
            <li key={l.id}>
              <a
                className={active ? 'active' : null}
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  active ? onHide() : onSelect(l.id)
                }}
                onMouseEnter={() => onSelect(l.id)}
              >
                <h4>{labels.league(l)}</h4>
              </a>
            </li>
          )
        })}
        <li key="more">
          <a
            className={selected === 'more' ? 'active' : null}
            href="#"
            onClick={(e) => {
              e.preventDefault()
              selected === 'more' ? onHide() : onSelect('more')
            }}
            onMouseEnter={() => onSelect('more')}
          >
            <h4>More</h4>
          </a>
        </li>
      </ol>
    </div>
  )
}

Menu.propTypes = {
  onHide: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headerLeagues: PropTypes.array
}
